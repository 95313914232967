import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';

import { CONFIG } from '../../config';
import { useLocales } from '../../hooks/useLocales';
import { useTheme } from '../../hooks/useTheme';
import { Image } from 'react-bootstrap';

function Navigation(): React.ReactNode {
	const { currentLang, translate } = useLocales();
	const { theme } = useTheme();
	const { pathname } = useLocation();
	return (
		<div
			className={`d-none d-md-grid masterLayout-sidebarMenu masterLayout-sidebarMenu-width ${currentLang.dir}-rounded-leading t-${theme}-bg-secondary-highlight p-0`}
		>
			<Container
				className='themed-container p-0 py-5 m-0 w-100 overflow-scroll'
				fluid={true}
			>
				{Object.values(CONFIG.Apps).map((item, index) => {
					const isActivePath = pathname.includes(item.path);
					if (item.children) {
						return (
							<div
								key={`${item.title}-${index}`}
								className={`p-0 m-0 py-3 font-md ${
									currentLang.dir
								}-sidebarMenu-item ${
									isActivePath
										? `${
												currentLang.dir
										  }-sidebarMenu-item-active t-${theme}-text-primary-highlight ${
												theme === 'light'
													? 't-light-bg-exception'
													: `t-${theme}-bg-secondary`
										  } t-${theme}-border-accent`
										: `sidebarMenu-item-inactive t-${theme}-sidebarMenu-item-hover t-${theme}-text-primary-with-alpha`
								}`}
							>
								<div className='masterLayout-sidebarMenu-menuItem'>
									<Image
										src={isActivePath ? item.icon.active : item.icon.inactive}
										alt={translate(item.title)}
										width={'25px'}
									/>
									<div
										className={`masterLayout-sidebarMenu-menuItem-title ${currentLang.dir}-secondaryFont`}
									>
										{translate(item.title)}
									</div>
								</div>
								<div className='masterLayout-sidebarMenu-menuChildren'>
									{Object.values(item.children).map((subItem, subIndex) => {
										return (
											<NavLink
												className='naked'
												key={`${subItem.title}-${subIndex}`}
												to={subItem.path}
											>
												{({ isActive }) => (
													<div
														className={`masterLayout-sidebarMenu-menuChildren-item d-flex flex-gap-1 align-items-center ${
															currentLang.dir
														}-secondaryFont ${
															isActive
																? `t-${theme}-text-primary-highlight`
																: `t-${theme}-sidebarMenu-item-hover t-${theme}-text-primary-with-alpha`
														}`}
													>
														<svg
															width='8'
															height='8'
															viewBox='0 0 14 14'
															fill='none'
															xmlns='http://www.w3.org/2000/svg'
														>
															<circle
																cx='6.5'
																cy='7'
																r='6.5'
																fill={isActive ? '#FFFFFF' : '#788A9F'}
															/>
														</svg>
														<span>{translate(subItem.title)}</span>
													</div>
												)}
											</NavLink>
										);
									})}
								</div>
							</div>
						);
					}
					return (
						<NavLink
							className='naked'
							key={`${item.title}-${index}`}
							to={item.path}
						>
							{({ isActive }) => (
								<div
									className={`p-0 m-0 py-3 font-md ${
										currentLang.dir
									}-sidebarMenu-item ${
										isActive
											? `${
													currentLang.dir
											  }-sidebarMenu-item-active t-${theme}-text-primary-highlight ${
													theme === 'light'
														? 't-light-bg-exception'
														: `t-${theme}-bg-secondary`
											  } t-${theme}-border-accent`
											: `sidebarMenu-item-inactive t-${theme}-sidebarMenu-item-hover t-${theme}-text-primary-with-alpha`
									}`}
								>
									<div className='masterLayout-sidebarMenu-menuItem'>
										<Image
											src={isActive ? item.icon.active : item.icon.inactive}
											alt={translate(item.title)}
											width={'25px'}
										/>
										<div
											className={`masterLayout-sidebarMenu-menuItem-title ${currentLang.dir}-secondaryFont`}
										>
											{translate(item.title)}
										</div>
									</div>
								</div>
							)}
						</NavLink>
					);
				})}
			</Container>
		</div>
	);
}

export default Navigation;
