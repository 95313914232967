import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { axiosInstance as axios } from '../../utils/axios';
import { dispatch } from '../store';
import { ENDPOINTS } from '../../config/endpoints';

export interface GiftcardModel {
	gift_card_id: number;
	hash?: string;
	serial_number: string;
	description: string;
	amount: number;
	status_code: string;
	expiration_date?: string;
	is_active?: number;
	created_at?: string;
	updated_at?: string;
	created_by?: string;
	used_by?: string;
	used_at?: string;
}
export interface GiftcardDetailModel {
	gift_card_id: number;
	serial_number: string;
	description: string;
	amount: number;
	status_code: string;
	expiration_date?: string;
	is_active?: number;
	created_at?: string;
	updated_at?: string;
	created_by?: string;
	used_by?: {
		username: string;
		email: string;
		display_name: string;
	};
	created_by_name?: string;
	created_by_email?: string;
	used_at?: string;
}

interface GiftcardSliceState {
	loading: boolean;
	record?: GiftcardDetailModel;
	list: GiftcardModel[];
	count: number;
	params: object;
}

export const giftcardSlice = createSlice({
	name: 'giftcard',
	initialState: {
		loading: false,
		list: [],
		count: 0,
		params: {
			page_number: 1,
		},
	} as GiftcardSliceState,
	reducers: {
		startLoading: (state) => {
			state.loading = true;
		},
		stopLoading: (state) => {
			state.loading = false;
		},
		getList: (
			state,
			action: PayloadAction<{ count: number; data: GiftcardModel[] }>
		) => {
			state.count = action.payload.count;
			state.list = action.payload.data;
			state.loading = false;
		},
		getRecord: (state, action: PayloadAction<GiftcardDetailModel>) => {
			state.record = action.payload;
			state.loading = false;
		},
		deleteRecord: (state, action: PayloadAction<string | number>) => {
			state.record = undefined;
			state.list = state.list.filter(
				(item) => item.gift_card_id === action.payload
			);
			state.loading = false;
		},
		activateRecord: (state, action: PayloadAction<string | number>) => {
			if (state.record?.gift_card_id === action.payload)
				state.record.is_active = 1;
			state.list = state.list.map((item) =>
				item.gift_card_id === action.payload ? { ...item, is_active: 1 } : item
			);
			state.loading = false;
		},
		deactivateRecord: (state, action: PayloadAction<string | number>) => {
			if (state.record?.gift_card_id === action.payload)
				state.record.is_active = 0;
			state.list = state.list.map((item) =>
				item.gift_card_id === action.payload ? { ...item, is_active: 0 } : item
			);
			state.loading = false;
		},
		clearRecord: (state) => {
			state.record = undefined;
			state.loading = false;
		},
		setParams: (state, action: PayloadAction<object>) => {
			state.params = action.payload;
		},
	},
});

export async function setParams(params: object): Promise<void> {
	dispatch(giftcardSlice.actions.setParams(params));
}

export async function getAll(params?: object): Promise<void> {
	try {
		dispatch(giftcardSlice.actions.startLoading());
		const response = await axios.get(ENDPOINTS.content.giftcard.data.getAll, {
			params,
		});
		dispatch(giftcardSlice.actions.getList(response.data.response));
	} catch (error) {
		dispatch(giftcardSlice.actions.stopLoading());
		throw error;
	}
}

export async function getInfo(id: string | number): Promise<void> {
	try {
		dispatch(giftcardSlice.actions.startLoading());
		const response = await axios.get(ENDPOINTS.content.giftcard.data.getInfo, {
			params: { gift_card_id: id },
		});
		dispatch(giftcardSlice.actions.getRecord(response.data.response));
	} catch (error) {
		dispatch(giftcardSlice.actions.clearRecord());
		throw error;
	}
}

export async function create(data: any): Promise<any> {
	try {
		dispatch(giftcardSlice.actions.startLoading());
		const response = await axios.post(
			ENDPOINTS.content.giftcard.data.create,
			data
		);
		dispatch(giftcardSlice.actions.stopLoading());
		return response.data.response;
	} catch (error) {
		dispatch(giftcardSlice.actions.stopLoading());
		throw error;
	}
}

export async function edit(data: any): Promise<void> {
	try {
		dispatch(giftcardSlice.actions.startLoading());
		await axios.post(ENDPOINTS.content.giftcard.data.edit, data);
		dispatch(giftcardSlice.actions.stopLoading());
	} catch (error) {
		dispatch(giftcardSlice.actions.stopLoading());
		throw error;
	}
}

export async function activate(id: string | number): Promise<void> {
	try {
		dispatch(giftcardSlice.actions.startLoading());
		await axios.post(ENDPOINTS.content.giftcard.data.activate, {
			gift_card_id: id,
		});
		dispatch(giftcardSlice.actions.activateRecord(id));
	} catch (error) {
		dispatch(giftcardSlice.actions.stopLoading());
		throw error;
	}
}

export async function deactivate(id: string | number): Promise<void> {
	try {
		dispatch(giftcardSlice.actions.startLoading());
		await axios.post(ENDPOINTS.content.giftcard.data.deactivate, {
			gift_card_id: id,
		});
		dispatch(giftcardSlice.actions.deactivateRecord(id));
	} catch (error) {
		dispatch(giftcardSlice.actions.stopLoading());
		throw error;
	}
}
