import { format } from 'date-fns';
import arLocale from 'date-fns/locale/ar-SA';

const Formatter = {
	longStringToShortWithDots: function (
		text: string,
		max: number,
		dots: number
	) {
		return text?.length > max
			? text?.substring(0, max - dots) + '.'.repeat(dots)
			: text;
	},
	getSlug: function (text: string) {
		return text.trim().toLowerCase().replaceAll('-', '_').replaceAll(' ', '_');
	},
	getFilenameFormURL: function (text: string) {
		if (typeof text !== 'string') return '';
		const splitText: string[] = text.split('/');
		return splitText[splitText.length - 1];
	},
	getDateTimeLocale: function (
		date: string | Date,
		locale: string,
		frmt: string = 'dd MMMM yyyy p'
	) {
		const options = locale === 'ar' ? { locale: arLocale } : {};
		return format(new Date(date), frmt, options);
	},
	getOrdinal: function (value: number) {
		if (!Number.isInteger(value) || value < 1) return `${value}`;
		const remainder10 = value % 10;
		const remainder100 = value % 100;
		return `${value}${
			remainder10 === 1 && remainder100 !== 11
				? 'st'
				: remainder10 === 2 && remainder100 !== 12
				? 'nd'
				: remainder10 === 3 && remainder100 !== 13
				? 'rd'
				: 'th'
		}`;
	},
	getShortNumber: function (value: number) {
		if (!Number.isInteger(value) || value < 1) return `${value}`;
		if (value >= 1000000) return `${(value / 1000000).toFixed(1)}M`;
		if (value >= 1000) return `${(value / 1000).toFixed(1)}K`;
		return `${value}`;
	},
};

export default Formatter;
