import {
	persistReducer,
	persistStore,
	REHYDRATE,
	PERSIST,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';
import {
	type TypedUseSelectorHook,
	useDispatch as useAppDispatch,
	useSelector as useAppSelector,
} from 'react-redux';
import rootReducer from './rootReducer';

const persistConfig = {
	key: 'stc-play',
	storage,
	whitelist: ['auth', 'geo'],
};

const persistedReducers = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
	reducer: persistedReducers,
	middleware: (getDefaultMiddleware) => {
		const defaultMiddleware = getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: {
				ignoredActions: [REHYDRATE, PERSIST],
			},
		});
		return defaultMiddleware;
	},
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const dispatch = store.dispatch;
export const useDispatch = (): AppDispatch => useAppDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;
