import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { axiosInstance as axios } from '../../utils/axios';
import { dispatch } from '../store';
import { ENDPOINTS } from '../../config/endpoints';

export interface LayoutModel {
	page_layout_id: number;
	name_en: string;
	name_ar: string;
	section_type: 'slider' | 'static_banner' | 'carousel' | 'section';
	reference_type: 'menu' | 'banner';
	reference_id: number;
	sort_id: number;
	is_active: number;
	is_deleted: number;
	created_at?: string;
	updated_at?: string;
	item_info: {
		menu_id?: number;
		banner_id?: number;
		menu_type_code?: string;
		banner_type?: string;
		is_active: number;
		name_en: string;
		name_ar: string;
	};
}

interface LayoutSliceState {
	loading: boolean;
	data: LayoutModel[];
}

export const layoutSlice = createSlice({
	name: 'layout',
	initialState: {
		loading: false,
		data: [],
	} as LayoutSliceState,
	reducers: {
		startLoading: (state) => {
			state.loading = true;
		},
		stopLoading: (state) => {
			state.loading = false;
		},
		getData: (state, action: PayloadAction<LayoutModel[]>) => {
			state.data = action.payload;
			state.loading = false;
		},
	},
});

export async function getAll(): Promise<void> {
	try {
		dispatch(layoutSlice.actions.startLoading());
		const response = await axios.get(ENDPOINTS.content.layout.data.getAll);
		dispatch(layoutSlice.actions.getData(response.data.response));
	} catch (error) {
		dispatch(layoutSlice.actions.stopLoading());
		throw error;
	}
}

export async function add(data: any): Promise<number> {
	const response = await axios.post(ENDPOINTS.content.layout.data.add, data);
	return response.data.response?.layout_id;
}

export async function update(data: any): Promise<void> {
	await axios.post(ENDPOINTS.content.layout.data.update, data);
}

export async function deleteRecord(page_layout_id: string | number): Promise<void> {
	await axios.post(ENDPOINTS.content.layout.data.delete, { page_layout_id });
}

export async function sortRecords(page_layout_ids: number[]): Promise<void> {
	await axios.post(ENDPOINTS.content.layout.data.sorting, { page_layout_ids });
}

export async function activateRecord(page_layout_id: string | number): Promise<void> {
	await axios.post(ENDPOINTS.content.layout.data.activate, { page_layout_id });
}

export async function deactivateRecord(page_layout_id: string | number): Promise<void> {
	await axios.post(ENDPOINTS.content.layout.data.deactivate, { page_layout_id });
}
