import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactNotifications } from 'react-notifications-component';

import { persistor, store } from './redux/store';

import { AuthProvider } from './context/AuthContext';
import Router from './routes';
import { initializeLocales } from './hooks/useLocales';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/dir-ltr.scss';
import './assets/scss/dir-rtl.scss';
import './assets/scss/theme-light.scss';
import './assets/scss/theme-dark.scss';
import './assets/scss/theme-blackout.scss';
import './assets/scss/global.scss';
import 'react-notifications-component/dist/theme.css';

function App(): React.ReactNode {
	useEffect(() => {
		initializeLocales();
	}, []);
	return (
		<StoreProvider store={store}>
			<PersistGate
				loading={null}
				persistor={persistor}
			>
				<HelmetProvider>
					<AuthProvider>
						<BrowserRouter>
							<ReactNotifications />
							<Router />
						</BrowserRouter>
					</AuthProvider>
				</HelmetProvider>
			</PersistGate>
		</StoreProvider>
	);
}

export default App;
