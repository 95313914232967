import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { axiosInstance as axios } from '../../utils/axios';
import { dispatch } from '../store';
import { ENDPOINTS } from '../../config/endpoints';

export interface SubscriptionModel {
	subscription_id: number;
	vendor_reference_id: string;
	is_active: number;
	is_deleted: number;
	name_en: string;
	name_ar: string;
	vendor_code: string;
	username: string;
}
export interface SubscriptionDetailsModel {
	username: string;
	display_name: string;
	is_official_account: number;
	avatar_url?: string;
	email: string;
	mobile: string;
	is_active: number;
	is_deleted: number;
	is_banned: number;
	subscription_details: {
		subscription_id: number;
		reference_vendor_id: string;
		subscription_mobile: string;
		vendor_code: string;
		amount: number;
		bill_cycle: string;
		start_date: string;
		end_date: string;
		subscription_active: number;
		subscription_deleted: number;
		created_at: string;
		updated_at: string;
		activated_at: string;
		deactivated_at?: string;
		deleted_at?: string;
		reason?: string;
	};
	subscription_history: Array<{
		created_at: string;
		action: string;
		reference_transaction_id: string;
		is_successful: number;
		processed_by: string;
	}>;
	recharge_schedule: Array<{
		bill_cycle: string;
		amount: number;
		is_recharged: number;
		is_deleted: number;
		is_suspended: number;
	}>;
}

interface SubscriptionSliceState {
	loading: boolean;
	record?: SubscriptionDetailsModel;
	list: SubscriptionModel[];
	count: number;
	params: object;
}

export const subscriptionSlice = createSlice({
	name: 'subscription',
	initialState: {
		loading: false,
		list: [],
		count: 0,
		params: {},
	} as SubscriptionSliceState,
	reducers: {
		startLoading: (state) => {
			state.loading = true;
		},
		stopLoading: (state) => {
			state.loading = false;
		},
		getList: (
			state,
			action: PayloadAction<{ count: number; data: SubscriptionModel[] }>
		) => {
			state.count = action.payload.count;
			state.list = action.payload.data;
			state.loading = false;
		},
		getRecord: (state, action: PayloadAction<SubscriptionDetailsModel>) => {
			state.record = action.payload;
			state.loading = false;
		},
		clearRecord: (state) => {
			state.record = undefined;
			state.loading = false;
		},
		setParams: (state, action: PayloadAction<object>) => {
			state.params = action.payload;
		},
	},
});

export async function setParams(params: object): Promise<void> {
	dispatch(subscriptionSlice.actions.setParams(params));
}

export async function getAll(params?: object): Promise<void> {
	try {
		dispatch(subscriptionSlice.actions.startLoading());
		const response = await axios.get(ENDPOINTS.crm.vendor.subscription.getAll, {
			params,
		});
		dispatch(subscriptionSlice.actions.getList(response.data.response));
	} catch (error) {
		dispatch(subscriptionSlice.actions.stopLoading());
		throw error;
	}
}

export async function getInfo(subscription_id: number | string): Promise<void> {
	try {
		dispatch(subscriptionSlice.actions.startLoading());
		const response = await axios.get(
			ENDPOINTS.crm.vendor.subscription.getInfo,
			{
				params: { subscription_id },
			}
		);
		dispatch(subscriptionSlice.actions.getRecord(response.data.response));
	} catch (error) {
		dispatch(subscriptionSlice.actions.clearRecord());
		throw error;
	}
}

export async function rechargeSubscription(body: any): Promise<void> {
	await axios.post(ENDPOINTS.crm.vendor.subscription.recharge, body);
}
