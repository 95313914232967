import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { useLocales } from '../../hooks/useLocales';
import { useTheme } from '../../hooks/useTheme';
// Redux
import { useSelector } from '../../redux/store';
// Config
import { SEARCH } from '../../config/search';

import icon_search from '../../assets/images/icon_search.png';

function Search(): React.ReactNode {
	const { currentLang, translate } = useLocales();
	const { pathname } = useLocation();
	const { theme } = useTheme();
	const [searchValue, setSearchValue] = useState<string>('');

	const TSEARCH: any = SEARCH;
	const currentSearch = TSEARCH[pathname];

	const state = useSelector((state: any) => state[currentSearch?.stateKey]);

	useEffect(() => {
		setSearchValue('');
	}, [pathname]);

	useEffect(() => {
		if (currentSearch) {
			setSearchValue(
				state.params?.[currentSearch?.searchKey ?? 'identifier'] ?? ''
			);
		}
	}, [state?.params]);

	const onSearch = (): void => {
		if (state) {
			currentSearch?.setParams({
				...(state.params ?? {}),
				[currentSearch?.searchKey ?? 'identifier']:
					searchValue !== '' ? searchValue : undefined,
			});
		}
	};

	if (!currentSearch) return <></>;
	return (
		<div
			className={`${
				currentLang.dir === 'ltr' ? 'rtl' : 'ltr'
			} d-flex w-100 p-0 m-0 position-relative `}
		>
			<div className={'h-100 p-0 m-0 w-100'}>
				<input
					id='search'
					type='text'
					value={searchValue}
					className={`${currentLang.dir} p-0 m-0 px-4 py-3 w-100 masterLayout-children-background-color t-${theme}-input t-${theme}-bg-secondary borderless t-${theme}-text-primary-highlight t-${theme}-caret-color-accent font-sm`}
					placeholder={translate('layout.search.placeholder')}
					onChange={(e) => {
						setSearchValue(e.target.value);
					}}
					onKeyDown={(e) => {
						e.key === 'Enter' && onSearch();
					}}
				/>
			</div>
			<div
				className={`d-flex align-items-center ${
					currentLang.dir === 'ltr' ? 'ps-3 ' : 'pe-3'
				} t-${theme}-bg-secondary line-height ${
					currentLang.dir
				}-rounded-leading position-relative`}
			>
				<Image
					src={icon_search}
					alt={'icon_search'}
					width={'15px'}
				/>
			</div>
		</div>
	);
}

export default Search;
