import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { axiosInstance as axios } from '../../utils/axios';
import { dispatch } from '../store';
import { ENDPOINTS } from '../../config/endpoints';

export interface CountryModel {
	name_en: string;
	name_ar: string;
	country_code_iso_2: string;
	country_code_iso_3: string;
	phone_code_iso: string;
	phone_number_length: number;
	phone_prefix_number: number;
	is_otp_required: number;
}

interface GeoSliceState {
	list: CountryModel[];
}

export const geoSlice = createSlice({
	name: 'geo',
	initialState: {
		list: [],
	} as GeoSliceState,
	reducers: {
		getList: (state, action: PayloadAction<CountryModel[]>) => {
			state.list = action.payload;
		},
	},
});

export async function getAllCountries(): Promise<void> {
	const response = await axios.get(
		ENDPOINTS.content.geo.country.getAllCountries
	);
	dispatch(geoSlice.actions.getList(response.data.response));
}
