import React, { type ElementType, Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { AppGuard, GuestGuard } from '../guards';
import { AppLayout, FreeLayout } from '../layout/index';
import { CONFIG } from '../config';

const Loadable =
	(Component: ElementType) =>
	(props: any): React.ReactNode =>
		(
			<Suspense fallback={<p>Loading...</p>}>
				<Component {...props} />
			</Suspense>
		);

const Page404 = Loadable(
	lazy(async () => await import('../pages/misc/Page404'))
);
const Page500 = Loadable(
	lazy(async () => await import('../pages/misc/Page500'))
);

const Login = Loadable(lazy(async () => await import('../pages/auth/Login')));
const ForgotPassword = Loadable(
	lazy(async () => await import('../pages/auth/ForgotPassword'))
);

// const Overview = Loadable(lazy(async () => await import('../pages/overview/Overview')))
const Product = Loadable(
	lazy(async () => await import('../pages/shop/product/Product'))
);
const ProductDetails = Loadable(
	lazy(async () => await import('../pages/shop/product/ProductDetails'))
);
const ProductEdit = Loadable(
	lazy(async () => await import('../pages/shop/product/ProductEdit'))
);
const Collection = Loadable(
	lazy(async () => await import('../pages/shop/collection/Collection'))
);
const CollectionDetails = Loadable(
	lazy(async () => await import('../pages/shop/collection/CollectionDetails'))
);
const CollectionEdit = Loadable(
	lazy(async () => await import('../pages/shop/collection/CollectionEdit'))
);

const Menu = Loadable(
	lazy(async () => await import('../pages/shop/menu/Menu'))
);

const Banner = Loadable(
	lazy(async () => await import('../pages/content/banner/Banner'))
);
const BannerDetails = Loadable(
	lazy(async () => await import('../pages/content/banner/BannerDetails'))
);
const BannerEdit = Loadable(
	lazy(async () => await import('../pages/content/banner/BannerEdit'))
);

const Layout = Loadable(
	lazy(async () => await import('../pages/content/layout/Layout'))
);

const Standings = Loadable(
	lazy(async () => await import('../pages/tms/standings/Standings'))
);

const Flag = Loadable(
	lazy(async () => await import('../pages/content/flag/Flag'))
);
const FlagDetails = Loadable(
	lazy(async () => await import('../pages/content/flag/FlagDetails'))
);

const Giftcard = Loadable(
	lazy(async () => await import('../pages/content/giftcard/Giftcard'))
);
const GiftcardDetails = Loadable(
	lazy(async () => await import('../pages/content/giftcard/GiftcardDetails'))
);
const GiftcardCreate = Loadable(
	lazy(async () => await import('../pages/content/giftcard/GiftcardCreate'))
);

const Stream = Loadable(
	lazy(async () => await import('../pages/content/stream/Stream'))
);
const StreamDetails = Loadable(
	lazy(async () => await import('../pages/content/stream/StreamDetails'))
);
const StreamEdit = Loadable(
	lazy(async () => await import('../pages/content/stream/StreamEdit'))
);

const Order = Loadable(
	lazy(async () => await import('../pages/oms/order/Order'))
);
const OrderDetails = Loadable(
	lazy(async () => await import('../pages/oms/order/OrderDetails'))
);

const Return = Loadable(
	lazy(async () => await import('../pages/oms/return/Return'))
);
const ReturnDetails = Loadable(
	lazy(async () => await import('../pages/oms/return/ReturnDetails'))
);

const Subscription = Loadable(
	lazy(async () => await import('../pages/crm/subscription/Subscription'))
);
const SubscriptionDetails = Loadable(
	lazy(
		async () => await import('../pages/crm/subscription/SubscriptionDetails')
	)
);

const User = Loadable(lazy(async () => await import('../pages/crm/user/User')));
const UserDetails = Loadable(
	lazy(async () => await import('../pages/crm/user/UserDetails'))
);
const UserEdit = Loadable(
	lazy(async () => await import('../pages/crm/user/UserEdit'))
);

const Router = (): React.ReactNode =>
	useRoutes([
		{
			path: '/',
			element: (
				<AppGuard>
					<AppLayout />
				</AppGuard>
			),
			children: [
				{
					index: true,
					// element: <Overview />,
					element: <Navigate to={CONFIG.Apps.shop.children.product.path} />,
				},
				{
					path: 'shop',
					children: [
						{
							index: true,
							element: <Navigate to={CONFIG.Apps.shop.children.product.path} />,
						},
						{
							path: 'product',
							children: [
								{
									index: true,
									element: <Product />,
								},
								{
									path: 'new',
									element: <ProductEdit />,
								},
								{
									path: ':id',
									children: [
										{
											index: true,
											element: <ProductDetails />,
										},
										{
											path: 'edit',
											element: <ProductEdit />,
										},
									],
								},
							],
						},
						{
							path: 'brand',
							children: [
								{
									index: true,
									element: <Collection brand={true} />,
								},
								{
									path: 'new',
									element: <CollectionEdit brand={true} />,
								},
								{
									path: ':id',
									children: [
										{
											index: true,
											element: <CollectionDetails brand={true} />,
										},
										{
											path: 'edit',
											element: <CollectionEdit brand={true} />,
										},
									],
								},
							],
						},
						{
							path: 'collection',
							children: [
								{
									index: true,
									element: <Collection brand={false} />,
								},
								{
									path: 'new',
									element: <CollectionEdit brand={false} />,
								},
								{
									path: ':id',
									children: [
										{
											index: true,
											element: <CollectionDetails brand={false} />,
										},
										{
											path: 'edit',
											element: <CollectionEdit brand={false} />,
										},
									],
								},
							],
						},
						{
							path: 'menu',
							children: [
								{
									index: true,
									element: (
										<Navigate to={CONFIG.Apps.shop.children.navigation.path} />
									),
								},
								{
									path: 'navigation',
									element: <Menu type='navigation' />,
								},
								{
									path: 'page',
									element: <Menu type='page' />,
								},
								{
									path: 'card',
									element: <Menu type='card' />,
								},
							],
						},
					],
				},
				{
					path: 'oms',
					children: [
						{
							index: true,
							element: <Navigate to={CONFIG.Apps.oms.children.order.path} />,
						},
						{
							path: 'order',
							children: [
								{
									index: true,
									element: <Order />,
								},
								{
									path: ':id',
									element: <OrderDetails />,
								},
							],
						},
						{
							path: 'return',
							children: [
								{
									index: true,
									element: <Return />,
								},
								{
									path: ':id',
									element: <ReturnDetails />,
								},
							],
						},
					],
				},
				{
					path: 'content',
					children: [
						{
							index: true,
							element: (
								<Navigate to={CONFIG.Apps.content.children.banner.path} />
							),
						},
						{
							path: 'banner',
							children: [
								{
									index: true,
									element: <Banner />,
								},
								{
									path: 'new',
									element: <BannerEdit />,
								},
								{
									path: ':id',
									children: [
										{
											index: true,
											element: <BannerDetails />,
										},
										{
											path: 'edit',
											element: <BannerEdit />,
										},
									],
								},
							],
						},
						{
							path: 'layout',
							element: <Layout />,
						},
						{
							path: 'flag',
							children: [
								{
									index: true,
									element: <Flag />,
								},
								{
									path: ':id',
									element: <FlagDetails />,
								},
							],
						},
						{
							path: 'giftcard',
							children: [
								{
									index: true,
									element: <Giftcard />,
								},
								{
									path: 'new',
									element: <GiftcardCreate />,
								},
								{
									path: ':id',
									element: <GiftcardDetails />,
								},
							],
						},
						{
							path: 'stream',
							children: [
								{
									index: true,
									element: <Stream />,
								},
								{
									path: 'new',
									element: <StreamEdit />,
								},
								{
									path: ':id',
									children: [
										{
											index: true,
											element: <StreamDetails />,
										},
										{
											path: 'edit',
											element: <StreamEdit />,
										},
									],
								},
							],
						},
					],
				},
				{
					path: 'tms',
					children: [
						{
							index: true,
							element: (
								<Navigate to={CONFIG.Apps.tms.children.standings.path} />
							),
						},
						{
							path: 'standings',
							element: <Standings />,
						},
					],
				},
				{
					path: 'crm',
					children: [
						{
							index: true,
							element: (
								<Navigate to={CONFIG.Apps.crm.children.subscription.path} />
							),
						},
						{
							path: 'subscription',
							children: [
								{
									index: true,
									element: <Subscription />,
								},
								{
									path: ':id',
									element: <SubscriptionDetails />,
								},
							],
						},
						{
							path: 'user',
							children: [
								{
									index: true,
									element: <User />,
								},
								{
									path: ':id',
									children: [
										{
											index: true,
											element: <UserDetails />,
										},
										{
											path: 'edit',
											element: <UserEdit />,
										},
									],
								},
							],
						},
					],
				},
			],
		},
		{
			path: '/',
			element: (
				<GuestGuard>
					<FreeLayout />
				</GuestGuard>
			),
			children: [
				{
					path: 'login',
					element: <Login />,
				},
				{
					path: 'forgotpassword',
					element: <ForgotPassword />,
				},
			],
		},
		{
			path: '/',
			element: <FreeLayout />,
			children: [
				{
					path: '500',
					element: <Page500 />,
				},
				{
					path: '404',
					element: <Page404 />,
				},
				{
					path: '*',
					element: <Navigate to={CONFIG.Pages.page404} />,
				},
			],
		},
	]);

export default Router;
