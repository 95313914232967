import shop_light from '../assets/images/new/menus/shop_light.png';
import shop_dark from '../assets/images/new/menus/shop_dark.png';
import order_light from '../assets/images/new/menus/order_light.png';
import order_dark from '../assets/images/new/menus/order_dark.png';
import content_light from '../assets/images/new/menus/content_light.png';
import content_dark from '../assets/images/new/menus/content_dark.png';
import users_light from '../assets/images/new/menus/users_light.png';
import users_dark from '../assets/images/new/menus/users_dark.png';
import play_light from '../assets/images/new/menus/play_light.png';
import play_dark from '../assets/images/new/menus/play_dark.png';

export const CONFIG = {
	Apps: {
		// overview: {
		//     id: 'overview',
		//     title: 'overview.title',
		//     icon: icon_home,
		//     path: '/',
		// },
		shop: {
			id: 'shop',
			title: 'shop.title',
			icon: {
				active: shop_light,
				inactive: shop_dark,
			},
			path: '/shop',
			children: {
				navigation: {
					id: 'menuNavigation',
					title: 'shop.menu.title.navigation',
					path: '/shop/menu/navigation',
				},
				collection: {
					id: 'collection',
					title: 'shop.collection.title.collection',
					path: '/shop/collection',
					pathNew: '/shop/collection/new',
					pathView: (id: string | number) => `/shop/collection/${id}`,
					pathEdit: (id: string | number) => `/shop/collection/${id}/edit`,
				},
				brand: {
					id: 'brand',
					title: 'shop.collection.title.brand',
					path: '/shop/brand',
					pathNew: '/shop/brand/new',
					pathView: (id: string | number) => `/shop/brand/${id}`,
					pathEdit: (id: string | number) => `/shop/brand/${id}/edit`,
				},
				page: {
					id: 'menuPage',
					title: 'shop.menu.title.page',
					path: '/shop/menu/page',
				},
				product: {
					id: 'product',
					title: 'shop.product.title',
					path: '/shop/product',
					pathNew: '/shop/product/new',
					pathView: (id: string | number) => `/shop/product/${id}`,
					pathEdit: (id: string | number) => `/shop/product/${id}/edit`,
				},
				card: {
					id: 'menuCard',
					title: 'shop.menu.title.card',
					path: '/shop/menu/card',
				},
			},
		},
		oms: {
			id: 'oms',
			title: 'oms.title',
			icon: {
				active: order_light,
				inactive: order_dark,
			},
			path: '/oms',
			children: {
				order: {
					id: 'order',
					title: 'oms.order.title',
					path: '/oms/order',
					pathView: (id: string | number) => `/oms/order/${id}`,
				},
				return: {
					id: 'return',
					title: 'oms.return.title',
					path: '/oms/return',
					pathView: (id: string | number) => `/oms/return/${id}`,
				},
			},
		},
		content: {
			id: 'content',
			title: 'content.title',
			icon: {
				active: content_light,
				inactive: content_dark,
			},
			path: '/content',
			children: {
				banner: {
					id: 'banner',
					title: 'content.banner.title',
					path: '/content/banner',
					pathNew: '/content/banner/new',
					pathView: (id: string | number) => `/content/banner/${id}`,
					pathEdit: (id: string | number) => `/content/banner/${id}/edit`,
				},
				layout: {
					id: 'layout',
					title: 'content.layout.title',
					path: '/content/layout',
				},
				flag: {
					id: 'flag',
					title: 'content.flag.title',
					path: '/content/flag',
					pathView: (id: string | number) => `/content/flag/${id}`,
				},
				giftcard: {
					id: 'giftcard',
					title: 'content.giftcard.title',
					path: '/content/giftcard',
					pathNew: '/content/giftcard/new',
					pathView: (id: string | number) => `/content/giftcard/${id}`,
				},
				stream: {
					id: 'stream',
					title: 'content.stream.title',
					path: '/content/stream',
					pathNew: '/content/stream/new',
					pathView: (id: string | number) => `/content/stream/${id}`,
					pathEdit: (id: string | number) => `/content/stream/${id}/edit`,
				},
			},
		},
		tms: {
			id: 'tms',
			title: 'tms.title',
			icon: {
				active: play_light,
				inactive: play_dark,
			},
			path: '/tms',
			children: {
				standings: {
					id: 'standings',
					title: 'tms.standings.title',
					path: '/tms/standings',
				},
			},
		},
		crm: {
			id: 'crm',
			title: 'crm.title',
			icon: {
				active: users_light,
				inactive: users_dark,
			},
			path: '/crm',
			children: {
				subscription: {
					id: 'subscription',
					title: 'crm.subscription.title',
					path: '/crm/subscription',
					pathView: (id: string | number) => `/crm/subscription/${id}`,
				},
				user: {
					id: 'user',
					title: 'crm.user.title',
					path: '/crm/user',
					pathView: (id: string | number) => `/crm/user/${id}`,
					pathEdit: (id: string | number) => `/crm/user/${id}/edit`,
				},
			},
		},
	},
	Pages: {
		login: '/login',
		forgotpassword: '/forgotpassword',
		page404: '/404',
		page500: '/500',
	},
};
