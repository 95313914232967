import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { axiosInstance as axios } from '../../utils/axios';
import { dispatch } from '../store';
import { ENDPOINTS } from '../../config/endpoints';
import {
	OrderDetailsModel,
	PhysicalShipmentModel,
	ShipmentHistoryModel,
} from './order';

export interface ReturnModel {
	return_status_ar: string;
	return_status_en: string;
	invoice_number: string;
	return_product_id: number;
	return_id: number;
	order_id: number;
	order_product_id: number;
	product_serial_id: number;
	amount: number;
	return_type: string;
	refund_method?: string;
	status_code: string;
	return_reason_code: string;
	is_accepted: number;
	is_refunded: number;
	is_active: number;
	is_canceled: number;
	is_rejected: number;
	required_update: number;
	parent_shipment_id: number;
	reference_shipment_id: number;
	reject_reason_ar?: string;
	reject_reason_en?: string;
	created_at: string;
	updated_at: string;
	reference_user_id: number;
	username: string;
}
export interface ReturnDetailsModel {
	return_id: number;
	return_number: string;
	order_id: number;
	type: string;
	reference_user_id: number;
	status_code: string;
	refunded_amount: number;
	total_amount: number;
	wallet_refunded_amount: number;
	payment_refunded_amount: number;
	created_at: string;
	updated_at: string;
	is_active: number;
	return_product_id: number;
	order_product_id: number;
	product_serial_id: number;
	amount: number;
	return_type: string;
	refund_method?: string;
	return_reason_code: string;
	is_accepted: number;
	is_refunded: number;
	is_canceled: number;
	is_rejected: number;
	required_update: number;
	parent_shipment_id: number;
	reference_shipment_id: number;
	reject_reason_ar?: string;
	reject_reason_en?: string;
	product_id: number;
	store_code?: string;
	name: string;
	variant_id: number;
	barcode: string;
	supplier: string;
	sku: string;
	qty: number;
	actual_cost_unit_price: number;
	actual_cost_sub_total: number;
	actual_cost_total_tax: number;
	actual_cost_grand_total: number;
	selling_cost_unit_price: number;
	selling_cost_sub_total: number;
	selling_cost_total_tax: number;
	selling_cost_grand_total: number;
	discount_price: number;
	is_digital: number;
	is_deleted: number;
	is_sent: number;
	return_status_id: number;
	return_status_code: string;
	return_status_ar: string;
	return_status_en: string;
	physical_product_serial_id: number;
	imei_number?: string;
	reserved_store_code: string;
	move_order_id: string;
	collect_order_id?: string;
	sale_id?: string;
	item_code: string;
	tracking_number: string;
	is_reserved: number;
	is_collected: number;
	is_delivered: number;
	is_return_requested: number;
	reserved_at: string;
	collected_at?: string;
	deleted_at?: string;
	media: string[];
	username: string;
	transactions: Array<{
		transaction_id: number;
		wallet_id: number;
		reference_id: string;
		reference_type: string;
		status_code: string;
		invoice_number: string;
		type_code: string;
		amount: number;
		total_balance: number;
		total_balance_non_withdrawable: number;
		gateway_code: string;
		gateway_receipt_number?: string;
		gateway_reconciliation_number: string;
		is_withdrawable: number;
		posted_on: string;
		updated_at: string;
		subscription_balance: number;
	}>;
	invoice_number: string;
	order_info?: OrderDetailsModel;
	shipment_info?: PhysicalShipmentModel;
	history?: ShipmentHistoryModel[];
}
interface RejectReasonModel {
	return_reject_reason_id: number;
	reason_en: string;
	reason_ar: string;
	is_active: number;
	is_deleted: number;
}

interface ReturnSliceState {
	loading: boolean;
	record?: ReturnDetailsModel;
	list: ReturnModel[];
	reasons: RejectReasonModel[];
	count: number;
	params: object;
}

export const returnSlice = createSlice({
	name: 'return',
	initialState: {
		loading: false,
		list: [],
		reasons: [],
		count: 0,
		params: {},
	} as ReturnSliceState,
	reducers: {
		startLoading: (state) => {
			state.loading = true;
		},
		stopLoading: (state) => {
			state.loading = false;
		},
		getList: (
			state,
			action: PayloadAction<{ count: number; list: ReturnModel[] }>
		) => {
			state.count = action.payload.count;
			state.list = action.payload.list;
			state.loading = false;
		},
		getRecord: (state, action: PayloadAction<ReturnDetailsModel>) => {
			state.record = action.payload;
			state.loading = false;
		},
		clearRecord: (state) => {
			state.record = undefined;
			state.loading = false;
		},
		setParams: (state, action: PayloadAction<object>) => {
			state.params = action.payload;
		},
		getRejectReasons: (state, action: PayloadAction<RejectReasonModel[]>) => {
			state.reasons = action.payload;
		},
	},
});

export async function setParams(params: object): Promise<void> {
	dispatch(returnSlice.actions.setParams(params));
}

export async function getAll(params?: object): Promise<void> {
	try {
		dispatch(returnSlice.actions.startLoading());
		const response = await axios.get(ENDPOINTS.oms.return.data.getAll, {
			params,
		});
		dispatch(returnSlice.actions.getList(response.data.response));
	} catch (error) {
		dispatch(returnSlice.actions.stopLoading());
		throw error;
	}
}

export async function getInfo(id: number | string): Promise<void> {
	try {
		dispatch(returnSlice.actions.startLoading());
		const response = await axios.get(ENDPOINTS.oms.return.data.getInfo, {
			params: { return_product_id: id },
		});
		const orderResponse = await axios.get(ENDPOINTS.oms.order.data.getInfo, {
			params: { invoice_number: response.data.response.invoice_number },
		});
		const data = {
			...response.data.response,
			order_info: orderResponse.data.response,
			shipment_info: undefined,
			history: [],
		};
		if (response.data.response.reference_shipment_id) {
			const shipmentResponse = await axios.get(
				ENDPOINTS.oms.order.shipment.getInfo,
				{
					params: {
						shipment_physical_id: response.data.response.reference_shipment_id,
					},
				}
			);
			data.shipment_info = shipmentResponse.data.response;
			if (shipmentResponse.data.response) {
				const historyResponse = await axios.get(
					ENDPOINTS.oms.order.shipment.getInfo,
					{
						params: {
							shipment_id: response.data.response.reference_shipment_id,
						},
					}
				);
				data.history = historyResponse.data.response ?? [];
			}
		}
		dispatch(returnSlice.actions.getRecord(data));
		const responseRejectReason = await axios.get(
			ENDPOINTS.oms.return.data.rejectReason
		);
		dispatch(
			returnSlice.actions.getRejectReasons(responseRejectReason.data.response)
		);
	} catch (error) {
		dispatch(returnSlice.actions.clearRecord());
		throw error;
	}
}

export async function updateReturn(body: object): Promise<void> {
	await axios.post(ENDPOINTS.oms.return.data.update, body);
}

export async function refundReturn(
	return_product_id: number | string
): Promise<void> {
	await axios.post(ENDPOINTS.oms.return.refund.refund, { return_product_id });
}
