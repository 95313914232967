import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { axiosInstance as axios } from '../../utils/axios';
import { dispatch } from '../store';
import { ENDPOINTS } from '../../config/endpoints';

export const MENU_CODES = {
	navigation: 2,
	page: 2,
	card: 1,
};
export type menuCodeTypes = keyof typeof MENU_CODES;

export interface MenuModel {
	menu_id: number;
	parent_menu_id: number;
	menu_code: string;
	menu_type_code: string;
	name_en: string;
	name_ar: string;
	is_main: number;
	sort_id: number;
	is_active: number;
	is_parent: number;
	is_deleted: number;
	created_at: string;
	updated_at: string;
	media: string[];
	item_info?: {
		collection_id: number;
		name_en: string;
		name_ar: string;
	};
	banner?: {
		banner_id: number;
		menu_banner_id: number;
		name_en: string;
		name_ar: string;
	} | null;
}

export interface MenuContainer {
	record?: MenuModel;
	list: MenuContainer[];
	page_number: number;
	loadable: boolean;
}

interface MenuSliceState {
	loading?: number;
	navigationList: {
		list: MenuModel[];
		page_number: number;
		loadable: boolean;
		loading: boolean;
	};
	navigation: MenuContainer;
	page: MenuContainer;
	card: MenuContainer;
}

export const menuSlice = createSlice({
	name: 'menu',
	initialState: {
		navigationList: {
			list: [],
			page_number: 0,
			loadable: true,
			loading: false,
		},
		navigation: {
			list: [],
			page_number: 0,
			loadable: true,
		},
		page: {
			list: [],
			page_number: 0,
			loadable: true,
		},
		card: {
			list: [],
			page_number: 0,
			loadable: true,
		},
	} as MenuSliceState,
	reducers: {
		startLoading: (state, action: PayloadAction<number>) => {
			state.loading = action.payload;
		},
		stopLoading: (state) => {
			state.loading = undefined;
		},
		getData: (
			state,
			action: PayloadAction<{
				menu_ids: number[];
				page_number: number;
				code: menuCodeTypes;
				data: MenuModel[];
			}>
		) => {
			const data = action.payload.data.map((item) => ({
				record: item,
				list: [],
				page_number: 0,
				loadable: true,
			}));
			let tempState = state[action.payload.code];
			action.payload.menu_ids.forEach((item, index) => {
				if (index !== 0) {
					let tempState0 = tempState.list.find(
						(iitem) => iitem.record?.menu_id === item
					);
					if (tempState0) tempState = tempState0;
				}
			});
			tempState.list =
				action.payload.page_number === 1 ? data : [...tempState.list, ...data];
			tempState.page_number = action.payload.page_number;
			tempState.loadable = data.length >= 20;
			state.loading = undefined;
		},
		setActiveValue: (
			state,
			action: PayloadAction<{
				menu_ids: number[];
				code: menuCodeTypes;
				value: number;
			}>
		) => {
			let tempState = state[action.payload.code];
			action.payload.menu_ids.forEach((item, index) => {
				if (index !== 0) {
					let tempState0 = tempState.list.find(
						(iitem) => iitem.record?.menu_id === item
					);
					if (tempState0) tempState = tempState0;
				}
			});
			if (tempState.record) tempState.record.is_active = action.payload.value;
			state.loading = undefined;
		},
		deleteRecord: (
			state,
			action: PayloadAction<{
				menu_ids: number[];
				code: menuCodeTypes;
			}>
		) => {
			let tempState = state[action.payload.code];
			action.payload.menu_ids.forEach((item, index) => {
				if (index !== 0 && index !== action.payload.menu_ids.length - 1) {
					let tempState0 = tempState.list.find(
						(iitem) => iitem.record?.menu_id === item
					);
					if (tempState0) tempState = tempState0;
				}
			});
			if (tempState.list)
				tempState.list = tempState.list.filter(
					(item) =>
						item.record?.menu_id !==
						action.payload.menu_ids[action.payload.menu_ids.length - 1]
				);
			state.loading = undefined;
		},
		editRecord: (
			state,
			action: PayloadAction<{
				menu_ids: number[];
				code: menuCodeTypes;
				value: MenuModel;
			}>
		) => {
			let tempState = state[action.payload.code];
			action.payload.menu_ids.forEach((item, index) => {
				if (index !== 0 && index !== action.payload.menu_ids.length - 1) {
					let tempState0 = tempState.list.find(
						(iitem) => iitem.record?.menu_id === item
					);
					if (tempState0) tempState = tempState0;
				}
			});
			if (tempState.list) {
				let updated = false;
				tempState.list = tempState.list.map((item) => {
					if (item.record?.menu_id === action.payload.value.menu_id) {
						updated = true;
						return {
							record: action.payload.value,
							list: [],
							page_number: 0,
							loadable: true,
						};
					}
					return item;
				});
				if (!updated)
					tempState.list = [
						...tempState.list,
						{
							record: action.payload.value,
							list: [],
							page_number: 0,
							loadable: true,
						},
					];
			}
			state.loading = undefined;
		},
		sortRecords: (
			state,
			action: PayloadAction<{
				menu_ids: number[];
				code: menuCodeTypes;
				value: number[];
			}>
		) => {
			let tempState = state[action.payload.code];
			action.payload.menu_ids.forEach((item, index) => {
				if (index !== 0) {
					let tempState0 = tempState.list.find(
						(iitem) => iitem.record?.menu_id === item
					);
					if (tempState0) tempState = tempState0;
				}
			});
			if (tempState.list) {
				tempState.list = tempState.list.map((item, index) => {
					const iitem = tempState.list.find(
						(iitem) => iitem.record?.menu_id === action.payload.value[index]
					);
					if (iitem) return iitem;
					return item;
				});
			}
			state.loading = undefined;
		},
		editBannerRecord: (
			state,
			action: PayloadAction<{
				menu_ids: number[];
				code: menuCodeTypes;
				value: MenuModel['banner'];
			}>
		) => {
			const menu_id =
				action.payload.menu_ids[action.payload.menu_ids.length - 1];
			let tempState = state[action.payload.code];
			action.payload.menu_ids.forEach((item, index) => {
				if (index !== 0) {
					let tempState0 = tempState.list.find(
						(iitem) => iitem.record?.menu_id === item
					);
					if (tempState0) tempState = tempState0;
				}
			});
			if (tempState.record?.menu_id === menu_id) {
				tempState.record.banner = action.payload.value;
			}
			state.loading = undefined;
		},
		startNavigationListLoading: (state) => {
			state.navigationList.loading = true;
		},
		stopNavigationListLoading: (state) => {
			state.navigationList.loading = false;
		},
		getNavigationList: (
			state,
			action: PayloadAction<{
				page_number: number;
				data: MenuModel[];
			}>
		) => {
			state.navigationList.list =
				action.payload.page_number === 1
					? action.payload.data
					: [...state.navigationList.list, ...action.payload.data];
			state.navigationList.page_number = action.payload.page_number;
			state.navigationList.loadable = action.payload.data.length >= 20;
			state.navigationList.loading = false;
		},
	},
});

export async function getNavigationRoot(page_number: number): Promise<number> {
	try {
		dispatch(menuSlice.actions.startNavigationListLoading());
		const response = await axios.get(ENDPOINTS.shop.menu.data.getAll, {
			params: { menu_id: MENU_CODES.navigation, page_number },
		});
		const data = Array.isArray(response.data.response)
			? response.data.response.filter(
					(item: MenuModel) => item.menu_type_code === 'navigation'
			  )
			: [];
		dispatch(menuSlice.actions.getNavigationList({ page_number, data }));
		return (
			data?.find((item: MenuModel) => item.is_active)?.menu_id ??
			data?.[0]?.menu_id ??
			undefined
		);
	} catch (error) {
		dispatch(menuSlice.actions.stopNavigationListLoading());
		throw error;
	}
}

export async function getAll(
	menu_ids: number[],
	page_number: number,
	code: menuCodeTypes
): Promise<void> {
	try {
		const parent_menu_id = menu_ids[menu_ids.length - 1];
		dispatch(menuSlice.actions.startLoading(parent_menu_id));
		const response = await axios.get(ENDPOINTS.shop.menu.data.getAll, {
			params: { menu_id: parent_menu_id, page_number },
		});
		let data = Array.isArray(response.data.response)
			? response.data.response
			: [];
		if (code !== 'navigation') {
			data = data?.filter(
				(item: MenuModel) => item.menu_type_code !== 'navigation'
			);
		}
		dispatch(
			menuSlice.actions.getData({
				menu_ids,
				page_number,
				code,
				data,
			})
		);
	} catch (error) {
		dispatch(menuSlice.actions.stopLoading());
		throw error;
	}
}

export async function getInfo(
	menu_ids: number[],
	code: menuCodeTypes
): Promise<void> {
	const menu_id = menu_ids[menu_ids.length - 1];
	const response = await axios.get(ENDPOINTS.shop.menu.data.getInfo, {
		params: { menu_id },
	});
	dispatch(
		menuSlice.actions.editRecord({
			menu_ids,
			code,
			value: response.data.response,
		})
	);
}

export async function getBannerInfo(
	menu_ids: number[],
	code: menuCodeTypes
): Promise<void> {
	const menu_id = menu_ids[menu_ids.length - 1];
	const response = await axios.get(ENDPOINTS.shop.menu.banner.getInfo, {
		params: { menu_id },
	});
	dispatch(
		menuSlice.actions.editBannerRecord({
			menu_ids,
			code,
			value: {
				menu_banner_id: response.data.response.menu_banner_id,
				banner_id: response.data.response.banner_id,
				name_ar: response.data.response.banner_info.name_ar,
				name_en: response.data.response.banner_info.name_en,
			},
		})
	);
}

export async function add(data: any): Promise<number> {
	const response = await axios.post(ENDPOINTS.shop.menu.data.add, data);
	return response.data.response.menu_id;
}

export async function update(data: any): Promise<void> {
	await axios.post(ENDPOINTS.shop.menu.data.update, data);
}

export async function deleteRecord(
	id: number,
	menu_ids: number[],
	code: menuCodeTypes
): Promise<void> {
	await axios.post(ENDPOINTS.shop.menu.data.delete, { menu_id: id });
	dispatch(menuSlice.actions.deleteRecord({ menu_ids, code }));
}

export async function activateRecord(
	id: number,
	menu_ids: number[],
	code: menuCodeTypes
): Promise<void> {
	await axios.post(ENDPOINTS.shop.menu.data.activate, { menu_id: id });
	dispatch(menuSlice.actions.setActiveValue({ menu_ids, code, value: 1 }));
}

export async function deactivateRecord(
	id: number,
	menu_ids: number[],
	code: menuCodeTypes
): Promise<void> {
	await axios.post(ENDPOINTS.shop.menu.data.deactivate, { menu_id: id });
	dispatch(menuSlice.actions.setActiveValue({ menu_ids, code, value: 0 }));
}

export async function sortRecords(
	body: any,
	menu_ids: number[],
	code: menuCodeTypes
): Promise<void> {
	await axios.post(ENDPOINTS.shop.menu.data.sorting, body);
	dispatch(
		menuSlice.actions.sortRecords({ menu_ids, code, value: body.menu_ids })
	);
}

export async function addCollection(data: object): Promise<void> {
	await axios.post(ENDPOINTS.shop.menu.collection.add, data);
}

export async function deleteCollection(data: object): Promise<void> {
	await axios.post(ENDPOINTS.shop.menu.collection.delete, data);
}

export async function addBanner(data: object): Promise<void> {
	await axios.post(ENDPOINTS.shop.menu.banner.add, data);
}

export async function deleteBanner(data: object): Promise<void> {
	await axios.post(ENDPOINTS.shop.menu.banner.delete, data);
}

export async function addProduct(data: object): Promise<void> {
	await axios.post(ENDPOINTS.shop.menu.product.add, data);
}

export async function deleteProduct(data: object): Promise<void> {
	await axios.post(ENDPOINTS.shop.menu.product.delete, data);
}
