import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { axiosInstance as axios, setSession } from '../../utils/axios';
import { dispatch, store } from '../store';
import { ENDPOINTS } from '../../config/endpoints';

interface UserModel {
	avatar_url?: string;
	created_at: string;
	display_name: string;
	email: string;
	is_active: number;
	is_deleted: number;
	is_master_admin: number;
	mobile: string;
	preferred_language: string;
	preferred_theme: string;
	preferred_timezone: string;
}

export interface AuthSliceState {
	isInitialized: boolean;
	user?: UserModel;
	token?: string;
	lang: 'en' | 'ar';
	theme: 'light' | 'dark' | 'blackout';
}

export const initialState = {
	isInitialized: false,
	user: null,
	token: null,
	lang: 'en',
	theme: 'blackout',
} as unknown as AuthSliceState;

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		initialize: (state) => {
			state.isInitialized = true;
		},
		login: (
			state,
			action: PayloadAction<{ user?: UserModel; token?: string }>
		) => {
			state.token = action.payload.token;
			state.user = action.payload.user;
		},
	},
});

export async function storeLogin(authState: AuthSliceState): Promise<void> {
	dispatch(authSlice.actions.login(authState));
}

export async function initialize(): Promise<void> {
	const token: string = store.getState().auth.token ?? '';
	setSession(token);
	dispatch(authSlice.actions.initialize());
}

export async function login(
	identifier: string,
	password: string,
	otp_channel: 'sms' | 'email'
): Promise<AuthSliceState> {
	const response = await axios.post(ENDPOINTS.admin.account.access.login, {
		identifier,
		password,
		otp_channel,
	});
	const responseInfo = await axios.post(ENDPOINTS.admin.account.data.getInfo, {
		identifier,
	});
	setSession(response.data.response.token);
	return {
		...response.data.response,
		user: responseInfo.data.response[0],
	};
}

export async function requestOTP(
	identifier: string,
	type: 'login' | 'reset_password',
	otp_channel: 'sms' | 'email'
): Promise<void> {
	await axios.post(ENDPOINTS.admin.account.access.requestOTP, {
		identifier,
		otp_type: type,
		otp_channel,
	});
}

export async function verifyOTP(
	otp: string,
	type: 'login' | 'reset_password'
): Promise<void> {
	await axios.post(ENDPOINTS.admin.account.access.verifyOTP, {
		otp,
		otp_type: type,
	});
}

export async function logout(): Promise<void> {
	setSession();
	dispatch(authSlice.actions.login({ user: undefined, token: undefined }));
}

axios.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.response?.data?.error_code === 1010) logout();
		return Promise.reject(error);
	}
);

export async function resetPassword(
	identifier: string,
	password: string,
	otp: string
): Promise<void> {
	await axios.post(ENDPOINTS.admin.account.data.resetPassword, {
		identifier,
		password,
		otp,
	});
}
