// Redux
import { setParams as setProductParams } from '../redux/slices/product';
import { setParams as setBrandParams } from '../redux/slices/brand';
import { setParams as setCollectionParams } from '../redux/slices/collection';
import { setParams as setOrderParams } from '../redux/slices/order';
import { setParams as setReturnParams } from '../redux/slices/return';
import { setParams as setSubscriptionParams } from '../redux/slices/subscription';
import { setParams as setUserParams } from '../redux/slices/user';

export const SEARCH = {
	'/shop/product': {
		stateKey: 'product',
		setParams: setProductParams,
	},
	'/shop/brand': {
		stateKey: 'brand',
		setParams: setBrandParams,
	},
	'/shop/collection': {
		stateKey: 'collection',
		setParams: setCollectionParams,
	},
	'/oms/order': {
		stateKey: 'order',
		setParams: setOrderParams,
	},
	'/oms/return': {
		stateKey: 'return',
		setParams: setReturnParams,
	},
	'/crm/subscription': {
		stateKey: 'subscription',
		setParams: setSubscriptionParams,
	},
	'/crm/user': {
		stateKey: 'user',
		setParams: setUserParams,
	},
};
