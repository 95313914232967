export const ENDPOINTS = {
	admin: {
		account: {
			data: {
				getInfo: 'admin/account/data/getInfo',
				resetPassword: 'admin/account/data/resetPassword',
			},
			access: {
				login: 'admin/account/access/login',
				logout: 'admin/account/access/logout',
				requestOTP: 'admin/account/access/requestOTP',
				verifyOTP: 'admin/account/access/verifyOTP',
			},
		},
	},
	shop: {
		product: {
			data: {
				getAll: 'shop/product/data/getAll',
				getInfo: 'shop/product/data/getInfo',
				add: 'shop/product/data/add',
				update: 'shop/product/data/update',
				activate: 'shop/product/data/activate',
				deactivate: 'shop/product/data/deactivate',
				delete: 'shop/product/data/delete',
				lookup: 'shop/product/data/lookup',
			},
			seo: {
				add: 'shop/product/seo/add',
			},
			specification: {
				getType: 'shop/product/specification/getType',
			},
			media: {
				// generateLinks: 'shop/product/media/generateLinks',
				add: 'shop/product/media/add',
				update: 'shop/product/media/update',
			},
			variant: {
				getAll: 'shop/product/variant/getAll',
				update: 'shop/product/variant/update',
				activate: 'shop/product/variant/activate',
				deactivate: 'shop/product/variant/deactivate',
				delete: 'shop/product/variant/delete',
			},
			option: {
				getType: 'shop/product/option/getType',
				update: 'shop/product/option/update',
			},
			related: {
				getAll: 'shop/product/related/getAll',
				add: 'shop/product/related/add',
				remove: 'shop/product/related/remove',
				sorting: 'shop/product/related/sorting',
			},
		},
		collection: {
			data: {
				getAll: 'shop/collection/data/getAll',
				getInfo: 'shop/collection/data/getInfo',
				add: 'shop/collection/data/add',
				update: 'shop/collection/data/update',
				activate: 'shop/collection/data/activate',
				deactivate: 'shop/collection/data/deactivate',
				delete: 'shop/collection/data/delete',
			},
			seo: {
				add: 'shop/collection/seo/add',
			},
			product: {
				getAll: 'shop/collection/product/getAll',
				add: 'shop/collection/product/add',
				update: 'shop/collection/product/update',
				delete: 'shop/collection/product/delete',
			},
			banner: {
				getInfo: 'shop/collection/banner/getInfo',
				add: 'shop/collection/banner/add',
				delete: 'shop/collection/banner/delete',
			},
		},
		menu: {
			data: {
				lookup: 'shop/menu/data/lookup',
				getAll: 'shop/menu/data/getAll',
				getInfo: 'shop/menu/data/getInfo',
				add: 'shop/menu/data/add',
				update: 'shop/menu/data/update',
				activate: 'shop/menu/data/activate',
				deactivate: 'shop/menu/data/deactivate',
				delete: 'shop/menu/data/delete',
				sorting: 'shop/menu/data/sorting',
			},
			collection: {
				add: 'shop/menu/collection/add',
				delete: 'shop/menu/collection/delete',
			},
			banner: {
				getInfo: 'shop/menu/banner/getInfo',
				add: 'shop/menu/banner/add',
				delete: 'shop/menu/banner/delete',
			},
			product: {
				add: 'shop/menu/product/add',
				delete: 'shop/menu/product/delete',
			},
			item: {
				getInfo: 'shop/menu/item/getInfo',
			},
		},
	},
	content: {
		banner: {
			data: {
				getAll: 'content/banner/data/getAll',
				getInfo: 'content/banner/data/getInfo',
				add: 'content/banner/data/add',
				update: 'content/banner/data/update',
				activate: 'content/banner/data/activate',
				deactivate: 'content/banner/data/deactivate',
				delete: 'content/banner/data/delete',
			},
		},
		layout: {
			data: {
				getAll: 'content/layout/data/getAll',
				add: 'content/layout/data/add',
				update: 'content/layout/data/update',
				delete: 'content/layout/data/delete',
				sorting: 'content/layout/data/sorting',
				activate: 'content/layout/data/activate',
				deactivate: 'content/layout/data/deactivate',
			},
		},
		flag: {
			data: {
				getAll: 'content/flag/data/getAll',
				getInfo: 'content/flag/data/getInfo',
				resolve: 'content/flag/data/resolve',
			},
		},
		giftcard: {
			data: {
				getAll: 'content/giftcard/data/getAll',
				getInfo: 'content/giftcard/data/getInfo',
				create: 'content/giftcard/data/create',
				edit: 'content/giftcard/data/edit',
				activate: 'content/giftcard/data/activate',
				deactivate: 'content/giftcard/data/deactivate',
				export: 'content/giftcard/data/export',
			},
		},
		stream: {
			data: {
				getAll: 'content/stream/data/getAll',
				getInfo: 'content/stream/data/getInfo',
				add: 'content/stream/data/add',
				update: 'content/stream/data/update',
				activate: 'content/stream/data/activate',
				deactivate: 'content/stream/data/deactivate',
				delete: 'content/stream/data/delete',
			},
		},
		geo: {
			country: {
				getAllCountries: 'content/geo/country/getAllCountries',
			},
		},
		media: {
			data: {
				generateLinks: 'content/media/data/generateLinks',
			},
		},
	},
	oms: {
		order: {
			data: {
				getAll: 'oms/order/data/getAll',
				getInfo: 'oms/order/data/getInfo',
				export: 'oms/order/data/export',
			},
			shipment: {
				getInfo: 'oms/order/shipment/getInfo',
				getHistory: 'oms/order/shipment/getHistory',
			},
			voucher: {
				resend: 'oms/order/voucher/resend',
			},
		},
		return: {
			data: {
				getAll: 'oms/return/data/getAll',
				getInfo: 'oms/return/data/getInfo',
				export: 'oms/return/data/export',
				update: 'oms/return/data/update',
				getStatus: 'oms/return/data/getStatus',
				rejectReason: 'oms/return/data/rejectReason',
			},
			refund: {
				refund: 'oms/return/refund/refund',
			},
		},
	},
	crm: {
		vendor: {
			subscription: {
				getAll: 'crm/vendor/subscription/getAll',
				getInfo: 'crm/vendor/subscription/getInfo',
				export: 'crm/vendor/subscription/export',
				recharge: 'crm/vendor/subscription/refund',
			},
		},
		user: {
			data: {
				getAll: 'crm/user/data/getAll',
				getInfo: 'crm/user/data/getInfo',
				update: 'crm/user/account/edit',
				ban: 'crm/user/account/ban',
				unban: 'crm/user/account/unban',
				getBanReasons: 'crm/user/account/getBanReasons',
				reactivate: 'crm/user/account/reactivate',
			},
		},
	},
	tms: {
		tournament: {
			data: {
				getUserTournaments: 'tms/tournament/data/getUserTournaments',
			},
		},
		ewc: {
			standings: {
				getAll: 'tms/ewc/standings/getAll',
				import: 'tms/ewc/standings/import',
				update: 'tms/ewc/standings/update',
				sorting: 'tms/ewc/standings/sorting',
			},
		},
	},
};
