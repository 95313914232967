import i18next from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next, useTranslation } from 'react-i18next';

import enTranslations from '../locales/en.json';
import arTranslations from '../locales/ar.json';

interface LanguageData {
	value: string;
	title: string;
	dir: 'ltr' | 'rtl';
}

const LANGUAGES: LanguageData[] = [
	{
		value: 'en',
		title: 'English',
		dir: 'ltr',
	},
	{
		value: 'ar',
		title: 'العربية',
		dir: 'rtl',
	},
];

export const initializeLocales = (): any => {
	const language = localStorage.getItem('i18nextLng') ?? 'en';
	i18next
		.use(detector)
		.use(initReactI18next)
		.init({
			compatibilityJSON: 'v3',
			debug: false,
			resources: {
				en: { translation: enTranslations },
				ar: { translation: arTranslations },
			},
			lng: language,
			fallbackLng: 'en',
			keySeparator: '.',
			interpolation: { escapeValue: false },
		});
};

export const useLocales = (): {
	currentLang: LanguageData;
	otherLangs: LanguageData[];
	translate: Function;
	translateValue: Function;
	changeLang: Function;
	LANGUAGES: LanguageData[];
} => {
	const { i18n, t: translate } = useTranslation();
	const language = i18n?.language ?? 'en';
	const currentLang =
		LANGUAGES.find((lang) => lang.value === language) ?? LANGUAGES[0];
	const otherLangs = LANGUAGES.filter(
		(lang) => lang.value !== currentLang?.value
	);

	const changeLang = (newLanguage: string): void => {
		i18n.changeLanguage(newLanguage);
	};

	const translateValue = (value: any): string => {
		if (typeof value === 'string') return value;
		if (typeof value === 'object') {
			if (value[currentLang.value]) return value[currentLang.value];
			const otherLang =
				LANGUAGES.find((lang) => lang.value !== currentLang.value) ??
				LANGUAGES[0];
			if (value[otherLang.value]) return value[otherLang.value];
			return value[currentLang.value];
		}
		return String(value);
	};

	return {
		currentLang,
		otherLangs,
		translate,
		translateValue,
		changeLang,
		LANGUAGES,
	};
};
