import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { axiosInstance as axios } from '../../utils/axios';
import { dispatch } from '../store';
import { ENDPOINTS } from '../../config/endpoints';

export interface StandingModel {
	ewc_standing_id: number;
	ewc_team_id: number;
	ewc_team_name: string;
	ewc_team_code: string;
	img_url: string;
	ewc_rank: number;
	ewc_sort_id: number;
	ewc_match_played: number;
	ewc_match_win: number;
	ewc_match_lose: number;
	ewc_points: number;
}

interface StandingsSliceState {
	loading: boolean;
	page_number: number;
	loadable: boolean;
	data: StandingModel[];
}

export const standingsSlice = createSlice({
	name: 'standings',
	initialState: {
		loading: false,
		loadable: true,
		page_number: 1,
		data: [],
	} as StandingsSliceState,
	reducers: {
		startLoading: (state) => {
			state.loading = true;
		},
		stopLoading: (state) => {
			state.loading = false;
		},
		getData: (
			state,
			action: PayloadAction<{
				page_number: number;
				data: StandingModel[];
			}>
		) => {
			state.data =
				action.payload.page_number === 1
					? action.payload.data
					: [...state.data, ...action.payload.data];
			state.page_number = action.payload.page_number;
			state.loadable = action.payload.data.length >= 20;
			state.loading = false;
		},
	},
});

export async function getAll(page_number: number): Promise<void> {
	try {
		dispatch(standingsSlice.actions.startLoading());
		const response = await axios.get(ENDPOINTS.tms.ewc.standings.getAll, {
			params: { page_number },
		});
		dispatch(
			standingsSlice.actions.getData({
				page_number,
				data: response.data.response?.list ?? [],
			})
		);
	} catch (error) {
		dispatch(standingsSlice.actions.stopLoading());
		throw error;
	}
}

export async function importTeams(file: File): Promise<void> {
	const body = new FormData();
	body.append('teams', file, file.name);
	await axios.post(ENDPOINTS.tms.ewc.standings.import, body, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
}

export async function update(data: any): Promise<void> {
	await axios.post(ENDPOINTS.tms.ewc.standings.update, data);
}

export async function sortRecords(data: object): Promise<void> {
	await axios.post(ENDPOINTS.tms.ewc.standings.sorting, data);
}
