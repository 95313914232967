import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Navigation from './Navigation';
import MobileMenu from './MobileMenu';
import { useLocales } from '../../hooks/useLocales';
import { useTheme } from '../../hooks/useTheme';

import '../../assets/scss/masterLayout.scss';

function AppLayout(): React.ReactNode {
	const { currentLang } = useLocales();
	const { theme } = useTheme();
	const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

	const toggleMobileMenu = (): void => {
		setMobileMenuOpen(!mobileMenuOpen);
	};

	return (
		<div
			style={{ direction: currentLang.dir }}
			className={`masterLayout-mainContainer h-100 t-${theme}-bg-primary`}
		>
			<div className={'masterLayout-bodyContainer'}>
				<MobileMenu
					mobileMenuOpen={mobileMenuOpen}
					toggleMobileMenu={toggleMobileMenu}
				/>
				<Header toggleMobileMenu={toggleMobileMenu} />

				{/*  */}
				<div
					className={`h-100 masterLayout-childrenContainer t-${theme}-bg-secondary not-scrollable`}
				>
					<div className='d-flex h-100 p-0 m-0'>
						<Navigation />
						{/* key xs={12} md={11} */}
						<div
							className={`m-0 p-0 px-5 ${currentLang.dir}-rounded-sm-trailing masterLayout-children h-100 w-100 `}
						>
							<Outlet />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AppLayout;
