import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { axiosInstance as axios } from '../../utils/axios';
import { dispatch } from '../store';
import { ENDPOINTS } from '../../config/endpoints';

export interface OrderModel {
	order_id: number;
	status_code: string;
	invoice_number: string;
	payment_method_code: string;
	updated_at: string;
	total_amount: number;
	username: string;
}
export interface OrderDetailsModel {
	billing_name: string;
	billing_email: string;
	billing_mobile: string;
	billing_address: string;
	billing_city: string;
	billing_country_code?: string;
	billing_longitude: string;
	billing_latitude: string;
	shipping_name: string;
	shipping_email: string;
	shipping_mobile: string;
	shipping_address: string;
	shipping_city: string;
	shipping_country_code?: string;
	shipping_longitude: string;
	shipping_latitude: string;
	fulfilment_method_code: string;
	order_id: number;
	lmd_code: string;
	invoice_number: string;
	status_code: string;
	payment_method_code: string;
	cancel_reason_code?: string;
	amount_tax: number;
	amount_grand_total: number;
	amount_sub_total: number;
	total_amount: number;
	amount_discount: number;
	wallet_discount: number;
	created_at: string;
	updated_at: string;
	username: string;
	digital_shipments: Array<{
		shipment_digital_id: number;
		order_id: number;
		order_product_id: number;
		shipment_name: string;
		sku: string;
		sent_to?: string;
		reference_receipt_number: string;
		reference_serial_number: string;
		reference_serial_id: string;
		reference_serial_value: string;
		reference_expiration_date: string;
		fulfilment_method_code: string;
		shipment_status_code: string;
		created_at: string;
	}>;
	physical_shipments: Array<{
		shipment_physical_id: number;
		order_id: number;
		reference_dal_shipment_id: number;
		reference_order_id: number;
		reference_order_number: string;
		reference_entity_id: string;
		reference_tracking_number?: string;
		fulfilled_by_vendor_code: string;
		shipped_by_vendor_code: string;
		shipment_status_code: string;
		driver_name?: string;
		driver_id_number?: string;
		driver_mobile?: string;
		created_at: string;
		updated_at: string;
		delivered_at: string;
		is_returend: number;
		parent_shipment_id?: number;
	}>;
	products: Array<{
		sku: string;
		name_ar: string;
		name_en: string;
		is_digital: number;
		supplier: string;
		product_code: string;
		order_product_id: number;
		product_id: number;
		variant_id: number;
		qty: number;
		selling_cost_unit_price: number;
	}>;
}
export interface PhysicalShipmentModel {
	shipment_physical_id: number;
	order_id: number;
	reference_dal_shipment_id: number;
	reference_order_id: number;
	reference_order_number: string;
	reference_entity_id: string;
	reference_tracking_number?: string;
	fulfilled_by_vendor_code: string;
	shipped_by_vendor_code: string;
	shipment_status_code: string;
	driver_name?: string;
	driver_id_number?: string;
	driver_mobile?: string;
	created_at: string;
	updated_at: string;
	delivered_at: string;
	is_returend: number;
	parent_shipment_id?: number;
	products: Array<{
		move_order_id: string;
		collect_order_id?: string;
		sale_id?: string;
		item_code: string;
		imei_number?: string;
		tracking_number: string;
		is_reserved: number;
		reserved_at: string;
		is_collected: number;
		collected_at?: string;
		is_delivered: number;
		is_return_requested: number;
		media_url: string;
		sku: string;
		name_ar: string;
		name_en: string;
		is_digital: number;
		supplier: string;
		product_code: string;
		order_product_id: number;
		order_id: number;
		product_id: number;
		store_code?: string;
		name: string;
		variant_id: number;
		barcode: string;
		qty: number;
		actual_cost_unit_price: number;
		actual_cost_sub_total: number;
		actual_cost_total_tax: number;
		actual_cost_grand_total: number;
		selling_cost_unit_price: number;
		selling_cost_sub_total: number;
		selling_cost_total_tax: number;
		selling_cost_grand_total: number;
		discount_price: number;
		is_active: number;
		is_deleted: number;
		is_sent: number;
		created_at: string;
		updated_at: string;
	}>;
}
export interface DigitalShipmentModel {
	shipment_digital_id: number;
	order_id: number;
	order_product_id: number;
	shipment_name: string;
	sku: string;
	sent_to?: string;
	reference_receipt_number: string;
	reference_serial_number: string;
	reference_serial_id: string;
	reference_serial_value: string;
	reference_expiration_date: string;
	fulfilment_method_code: string;
	shipment_status_code: string;
	created_at: string;
	products: Array<{
		media_url: string;
		sku: string;
		name_ar: string;
		name_en: string;
		is_digital: number;
		supplier: string;
		product_code: string;
		order_product_id: number;
		order_id: number;
		product_id: number;
		store_code?: string;
		name: string;
		variant_id: number;
		barcode: string;
		qty: number;
		actual_cost_unit_price: number;
		actual_cost_sub_total: number;
		actual_cost_total_tax: number;
		actual_cost_grand_total: number;
		selling_cost_unit_price: number;
		selling_cost_sub_total: number;
		selling_cost_total_tax: number;
		selling_cost_grand_total: number;
		discount_price: number;
		is_active: number;
		is_deleted: number;
		is_sent: number;
		created_at: string;
		updated_at: string;
	}>;
}
export interface ShipmentHistoryModel {
	status_code: string;
	name_ar: string;
	name_en: string;
	updated_at: string;
}

interface OrderSliceState {
	loading: boolean;
	record?: OrderDetailsModel;
	list: OrderModel[];
	count: number;
	params: object;
}

export const orderSlice = createSlice({
	name: 'order',
	initialState: {
		loading: false,
		list: [],
		count: 0,
		params: {},
	} as OrderSliceState,
	reducers: {
		startLoading: (state) => {
			state.loading = true;
		},
		stopLoading: (state) => {
			state.loading = false;
		},
		getList: (
			state,
			action: PayloadAction<{ count: number; data: OrderModel[] }>
		) => {
			state.count = action.payload.count;
			state.list = action.payload.data;
			state.loading = false;
		},
		getRecord: (state, action: PayloadAction<OrderDetailsModel>) => {
			state.record = action.payload;
			state.loading = false;
		},
		clearRecord: (state) => {
			state.record = undefined;
			state.loading = false;
		},
		setParams: (state, action: PayloadAction<object>) => {
			state.params = action.payload;
		},
	},
});

export async function setParams(params: object): Promise<void> {
	dispatch(orderSlice.actions.setParams(params));
}

export async function getAll(params?: object): Promise<void> {
	try {
		dispatch(orderSlice.actions.startLoading());
		const response = await axios.get(ENDPOINTS.oms.order.data.getAll, {
			params,
		});
		dispatch(orderSlice.actions.getList(response.data.response));
	} catch (error) {
		dispatch(orderSlice.actions.stopLoading());
		throw error;
	}
}

export async function getInfo(id: number | string): Promise<void> {
	try {
		dispatch(orderSlice.actions.startLoading());
		const response = await axios.get(ENDPOINTS.oms.order.data.getInfo, {
			params: { invoice_number: id },
		});
		dispatch(orderSlice.actions.getRecord(response.data.response));
	} catch (error) {
		dispatch(orderSlice.actions.clearRecord());
		throw error;
	}
}

export async function getInfoShipment(params: object): Promise<any> {
	const response = await axios.get(ENDPOINTS.oms.order.shipment.getInfo, {
		params,
	});
	return response.data.response;
}

export async function getShipmentHistory(
	shipment_id: number | string
): Promise<ShipmentHistoryModel[]> {
	const response = await axios.get(ENDPOINTS.oms.order.shipment.getHistory, {
		params: { shipment_id },
	});
	return response.data.response;
}

export async function resendVoucher(id: string | number): Promise<void> {
	await axios.post(
		ENDPOINTS.oms.order.voucher.resend,
		{},
		{ params: { invoice_number: id } }
	);
}
