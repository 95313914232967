import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { CONFIG } from '../config';

interface GuardProps {
	children?: React.ReactNode;
}

function AppGuard({ children }: GuardProps): React.ReactNode {
	const { user } = useAuth();
	if (!user) return <Navigate to={CONFIG.Pages.login} />;
	return <>{children}</>;
}

export default AppGuard;
