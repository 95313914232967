import React from 'react';
import { Link } from 'react-router-dom';
import Search from './Search';
import AccountMenu from './AccountMenu';
import { Row, Col } from 'reactstrap';
import { useLocales } from '../../hooks/useLocales';
import { useTheme } from '../../hooks/useTheme';
import { Image } from 'react-bootstrap';
import icon_menu from '../../assets/images/icon_menu.png';
import brand_logo_light_horizontal from '../../assets/images/brand_logo_light_horizontal.png';

interface HeaderProps {
	toggleMobileMenu: Function;
}

const Header: React.FC<HeaderProps> = ({
	toggleMobileMenu,
}): React.ReactNode => {
	const { currentLang } = useLocales();
	const { theme } = useTheme();
	return (
		<div className={'masterLayout-headerContainer p-4'}>
			<Row
				className={`m-0 p-0 align-items-center ${currentLang.dir}-primaryFont`}
			>
				<Col
					xs={2}
					sm={1}
					className={'p-0 m-0 d-block d-md-none'}
					onClick={() => toggleMobileMenu()}
				>
					<Image
						src={icon_menu}
						alt='icon_menu'
						width={'30px'}
					/>
				</Col>
				<Col
					md={2}
					xl={1}
					className={'p-0 m-0 d-none d-md-block'}
				>
					<Link
						className='naked'
						to='/'
					>
						<Image
							src={
								theme === 'blackout' || theme === 'dark'
									? brand_logo_light_horizontal
									: brand_logo_light_horizontal
							}
							alt='stcplay'
							width={'90px'}
						/>
					</Link>
				</Col>
				<Col className={'p-0 m-0'}>
					<Search />
				</Col>
				<Col
					md={2}
					className={'p-0 m-0'}
				>
					<AccountMenu />
				</Col>
			</Row>
		</div>
	);
};

export default Header;
