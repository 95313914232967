import React from 'react';
import { Outlet } from 'react-router-dom';
import { useLocales } from '../../hooks/useLocales';
import { useTheme } from '../../hooks/useTheme';
import { Row } from 'reactstrap';

import '../../assets/scss/freeLayout.scss';

function FreeLayout(): React.ReactNode {
	const { currentLang } = useLocales();
	const { theme } = useTheme();

	return (
		<Row
			style={{ direction: currentLang.dir }}
			className={`m-0 p-0 h-100 w-100 t-${theme}-bg-primary not-scrollable justify-content-center align-content-center text-center`}
		>
			<Outlet />
		</Row>
	);
}

export default FreeLayout;
