import React, { createContext, useEffect } from 'react';
import { useSelector, type RootState } from '../redux/store';
import { initialState, initialize, login } from '../redux/slices/auth';

const AuthContext = createContext({
	...initialState,
	login,
});

interface AuthProviderProps {
	children?: React.ReactNode;
}

function AuthProvider({ children }: AuthProviderProps): React.ReactNode {
	const state = useSelector((state: RootState) => state.auth);

	useEffect(() => {
		initialize();
	}, []);

	return (
		<AuthContext.Provider value={{ ...state, login }}>
			{children}
		</AuthContext.Provider>
	);
}

export { AuthContext, AuthProvider };
