import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { axiosInstance as axios } from '../../utils/axios';
import { dispatch } from '../store';
import { ENDPOINTS } from '../../config/endpoints';
//
import { OrderModel } from './order';
import { ReturnModel } from './return';
import { SubscriptionModel } from './subscription';

export interface UserModel {
	user_id: number;
	username: string;
	display_name: string;
	updated_at: string;
}

export interface UserDetailsModel {
	created_at?: string;
	updated_at?: string;
	user_id: number;
	provider: 'stcplay' | 'twitter' | 'facebook' | 'apple' | 'google';
	username: string;
	is_official_account?: number;
	is_active?: number;
	is_deleted?: number;
	is_banned?: number;
	ban_reason_code?: string;
	ban_reason?: string;
	ban_attachment?: string;
	banned_by?: string;
	is_2FA_required: number;
	preferred_2FA_channel: 'sms' | 'email';
	email?: string;
	is_email_verified?: number;
	mobile?: string;
	is_mobile_verified?: number;
	display_name: string;
	gender_code?: string;
	birthdate?: string;
	country_code?: string;
	referral_code?: string;
	total_usage?: number;
	referred_by_username?: string;
	bio?: string;
	avatar_url?: string;
	cover_banner_url?: string;
	total_followers: number;
	total_following: number;
	preferred_private_chat_code: string;
	preferred_private_chat_name: string;
	preferred_group_chat_code: string;
	preferred_group_chat_name: string;
	is_read_status_enabled?: number;
	is_matchmaking_enabled?: number;
	gaming_accounts: NetworkModel[];
	social_accounts: NetworkModel[];
	addresses: AddressModel[];
	default_address_id?: number;
	preferred_currency: string;
	preferred_language: string;
	preferred_timezone: string;
	delete_reason?: string;
}
export interface NetworkModel {
	social_network_code: string;
	social_network_account: string;
	sort_id: number;
}
export interface AddressModel {
	address_id?: number;
	label?: string;

	street?: string;
	district?: string;
	city: string;
	zip_code?: string;

	name: string;
	mobile: string;
	email?: string;

	is_default?: number;
}
interface TournamentModel {
	id: string;
	name: string;
	status: 'pending' | 'running' | 'completed';
}
interface BanReasonModel {
	reason_code: string;
	reason_en: string;
	reason_ar: string;
}
interface UserSliceState {
	loading: boolean;
	record?: UserDetailsModel;
	orders: {
		page_number: number;
		loading: boolean;
		count: number;
		list: OrderModel[];
	};
	returns: {
		page_number: number;
		loading: boolean;
		count: number;
		list: ReturnModel[];
	};
	subscriptions: {
		page_number: number;
		loading: boolean;
		count: number;
		list: SubscriptionModel[];
	};
	tournaments: {
		page_number: number;
		loading: boolean;
		count: number;
		list: TournamentModel[];
	};
	list: UserModel[];
	reasons: BanReasonModel[];
	count: number;
	params: object;
}

export const userSlice = createSlice({
	name: 'user',
	initialState: {
		loading: false,
		orders: {
			page_number: 0,
			loading: false,
			count: 0,
			list: [],
		},
		returns: {
			page_number: 0,
			loading: false,
			count: 0,
			list: [],
		},
		subscriptions: {
			page_number: 0,
			loading: false,
			count: 0,
			list: [],
		},
		tournaments: {
			page_number: 0,
			loading: false,
			count: 0,
			list: [],
		},
		list: [],
		reasons: [],
		count: 0,
		params: {},
	} as UserSliceState,
	reducers: {
		startLoading: (state) => {
			state.loading = true;
		},
		stopLoading: (state) => {
			state.loading = false;
		},
		getList: (
			state,
			action: PayloadAction<{ count: number; data: UserModel[] }>
		) => {
			state.count = action.payload.count;
			state.list = action.payload.data;
			state.loading = false;
		},
		getRecord: (state, action: PayloadAction<UserDetailsModel>) => {
			state.record = action.payload;
			state.loading = false;
		},
		clearRecord: (state) => {
			state.record = undefined;
			state.loading = false;
		},
		startListLoading: (
			state,
			action: PayloadAction<
				'orders' | 'returns' | 'subscriptions' | 'tournaments'
			>
		) => {
			state[action.payload].loading = true;
		},
		stopListLoading: (
			state,
			action: PayloadAction<
				'orders' | 'returns' | 'subscriptions' | 'tournaments'
			>
		) => {
			state[action.payload].loading = false;
		},
		getOrdersList: (
			state,
			action: PayloadAction<{
				page_number: number;
				count: number;
				data: OrderModel[];
			}>
		) => {
			state.orders.page_number = action.payload.page_number;
			state.orders.count = action.payload.count;
			state.orders.list =
				action.payload.page_number === 1
					? action.payload.data
					: [...(state.orders.list ?? []), ...action.payload.data];
			state.orders.loading = false;
		},
		getReturnsList: (
			state,
			action: PayloadAction<{
				page_number: number;
				count: number;
				data: ReturnModel[];
			}>
		) => {
			state.returns.page_number = action.payload.page_number;
			state.returns.count = action.payload.count;
			state.returns.list =
				action.payload.page_number === 1
					? action.payload.data
					: [...(state.returns.list ?? []), ...action.payload.data];
			state.returns.loading = false;
		},
		getSubscriptionsList: (
			state,
			action: PayloadAction<{
				page_number: number;
				count: number;
				data: SubscriptionModel[];
			}>
		) => {
			state.subscriptions.page_number = action.payload.page_number;
			state.subscriptions.count = action.payload.count;
			state.subscriptions.list =
				action.payload.page_number === 1
					? action.payload.data
					: [...(state.subscriptions.list ?? []), ...action.payload.data];
			state.subscriptions.loading = false;
		},
		getTournamentsList: (
			state,
			action: PayloadAction<{
				page_number: number;
				data: TournamentModel[];
			}>
		) => {
			state.tournaments.page_number = action.payload.page_number;
			state.tournaments.list =
				action.payload.page_number === 1
					? action.payload.data
					: [...(state.tournaments.list ?? []), ...action.payload.data];
			state.tournaments.count = state.tournaments.list.length;
			state.tournaments.loading = false;
		},
		setParams: (state, action: PayloadAction<object>) => {
			state.params = action.payload;
		},
		getBanReasons: (state, action: PayloadAction<BanReasonModel[]>) => {
			state.reasons = action.payload;
		},
	},
});

export async function setParams(params: object): Promise<void> {
	dispatch(userSlice.actions.setParams(params));
}

export async function getAll(params?: object): Promise<void> {
	try {
		dispatch(userSlice.actions.startLoading());
		const response = await axios.get(ENDPOINTS.crm.user.data.getAll, {
			params,
		});
		dispatch(userSlice.actions.getList(response.data.response));
	} catch (error) {
		dispatch(userSlice.actions.stopLoading());
		throw error;
	}
}

export async function getInfo(
	user_id: string | number
): Promise<UserDetailsModel | undefined> {
	try {
		dispatch(userSlice.actions.startLoading());
		const response = await axios.get(ENDPOINTS.crm.user.data.getInfo, {
			params: { user_id },
		});
		dispatch(userSlice.actions.getRecord(response.data.response));
		const responseBanReason = await axios.get(
			ENDPOINTS.crm.user.data.getBanReasons
		);
		dispatch(userSlice.actions.getBanReasons(responseBanReason.data.response));
		return response.data.response;
	} catch (error) {
		dispatch(userSlice.actions.clearRecord());
		throw error;
	}
}

export async function getOrdersAll(params?: any): Promise<void> {
	try {
		dispatch(userSlice.actions.startListLoading('orders'));
		const response = await axios.get(ENDPOINTS.oms.order.data.getAll, {
			params,
		});
		dispatch(
			userSlice.actions.getOrdersList({
				page_number: params?.page_number ?? 1,
				count: response.data.response.count ?? 0,
				data: response.data.response.data ?? [],
			})
		);
	} catch (error) {
		dispatch(userSlice.actions.stopListLoading('orders'));
		throw error;
	}
}

export async function getReturnsAll(params?: any): Promise<void> {
	try {
		dispatch(userSlice.actions.startListLoading('returns'));
		const response = await axios.get(ENDPOINTS.oms.return.data.getAll, {
			params,
		});
		dispatch(
			userSlice.actions.getReturnsList({
				page_number: params?.page_number ?? 1,
				count: response.data.response.count ?? 0,
				data: response.data.response.list ?? [],
			})
		);
	} catch (error) {
		dispatch(userSlice.actions.stopListLoading('returns'));
		throw error;
	}
}

export async function getSubscriptionsAll(params?: any): Promise<void> {
	try {
		dispatch(userSlice.actions.startListLoading('subscriptions'));
		const response = await axios.get(ENDPOINTS.crm.vendor.subscription.getAll, {
			params,
		});
		dispatch(
			userSlice.actions.getSubscriptionsList({
				page_number: params?.page_number ?? 1,
				count: response.data.response.count ?? 0,
				data: response.data.response.data ?? [],
			})
		);
	} catch (error) {
		dispatch(userSlice.actions.stopListLoading('subscriptions'));
		throw error;
	}
}

export async function getTournamentsAll(params?: any): Promise<void> {
	try {
		dispatch(userSlice.actions.startListLoading('tournaments'));
		const response = await axios.get(
			ENDPOINTS.tms.tournament.data.getUserTournaments,
			{ params }
		);
		const data = [
			...(response.data.response.acitve_tournamanets ?? []),
			...(response.data.response.ended_tournaments ?? []),
		];
		dispatch(
			userSlice.actions.getTournamentsList({
				page_number: params?.page_number ?? 1,
				data: data,
			})
		);
	} catch (error) {
		dispatch(userSlice.actions.stopListLoading('tournaments'));
		throw error;
	}
}

export async function update(data: any): Promise<void> {
	try {
		dispatch(userSlice.actions.startLoading());
		await axios.post(ENDPOINTS.crm.user.data.update, data);
		dispatch(userSlice.actions.stopLoading());
	} catch (error) {
		dispatch(userSlice.actions.stopLoading());
		throw error;
	}
}

export async function banUser(body: any): Promise<void> {
	await axios.post(ENDPOINTS.crm.user.data.ban, body);
}

export async function unbanUser(body: any): Promise<void> {
	await axios.post(ENDPOINTS.crm.user.data.unban, body);
}

export async function reactivateUser(body: any): Promise<void> {
	await axios.post(ENDPOINTS.crm.user.data.reactivate, body);
}
