import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { axiosInstance as axios } from '../../utils/axios';
import { dispatch } from '../store';
import { ENDPOINTS } from '../../config/endpoints';

export interface VariantModel {
	variant_id: number;
	product_id: number;
	sku: string;
	barcode: string;
	quantity: number;
	is_preorder: number;
	taxable: number;
	selling_price: number;
	original_cost: number;
	unit_price: number;
	is_active: number;
	is_deleted: number;
	created_at: string;
	updated_at: string;
	media?: Array<{ media_url: string; alt: string }>;
	specification: Array<{
		type_code: string;
		name_ar: string;
		name_en: string;
		value_ar: string;
		value_en: string;
	}>;
	options: string[];
}
export interface ProductModel {
	product_id: number;
	product_code: string;
	name_ar: string;
	name_en: string;
	description_ar?: string;
	description_en?: string;
	metadata?: string;
	featured_img?: string;
	brand_id?: number;
	brand?: {
		name_ar: string;
		name_en: string;
	};
	supplier?: string;
	is_digital?: number;
	lowest_actual_cost?: number;
	highest_actual_cost?: number;
	lowest_selling_price?: number;
	highest_selling_price?: number;
	is_active?: number;
	is_deleted?: number;
	created_at?: string;
	updated_at?: string;
	variants?: VariantModel[];
	related_products?: any[];
	total_products?: number;
	collections?: any[];
	options?: any[];
	media?: Array<{ media_url: string; alt: string }>;
}
export interface SpecificationTypeModel {
	specification_type_id: number;
	specification_type_code: string;
	name_en: string;
	name_ar: string;
	is_deleted: number;
}

interface ProductSliceState {
	loading: boolean;
	relatedProductLoading: boolean;
	record?: ProductModel;
	list: ProductModel[];
	specificationTypes: SpecificationTypeModel[];
	count: number;
	params: object;
}

export const productSlice = createSlice({
	name: 'product',
	initialState: {
		loading: false,
		relatedProductLoading: false,
		record: null,
		list: [],
		specificationTypes: [],
		count: 0,
		params: {},
	} as unknown as ProductSliceState,
	reducers: {
		startLoading: (state) => {
			state.loading = true;
		},
		stopLoading: (state) => {
			state.loading = false;
		},
		getList: (
			state,
			action: PayloadAction<{ count: number; list: ProductModel[] }>
		) => {
			state.count = action.payload.count;
			state.list = action.payload.list;
			state.loading = false;
		},
		getSpecificationTypes: (state, action: PayloadAction<SpecificationTypeModel[]>) => {
			state.specificationTypes = action.payload;
		},
		getRecord: (state, action: PayloadAction<ProductModel>) => {
			state.record = action.payload;
			state.loading = false;
		},
		deleteRecord: (state, action: PayloadAction<string | number>) => {
			state.record = undefined;
			state.list = state.list.filter(
				(item) => item.product_id === action.payload
			);
			state.loading = false;
		},
		activateRecord: (state, action: PayloadAction<string | number>) => {
			if (state.record?.product_id === action.payload)
				state.record.is_active = 1;
			state.list = state.list.map((item) =>
				item.product_id === action.payload ? { ...item, is_active: 1 } : item
			);
			state.loading = false;
		},
		deactivateRecord: (state, action: PayloadAction<string | number>) => {
			if (state.record?.product_id === action.payload)
				state.record.is_active = 0;
			state.list = state.list.map((item) =>
				item.product_id === action.payload ? { ...item, is_active: 0 } : item
			);
			state.loading = false;
		},
		setParams: (state, action: PayloadAction<object>) => {
			state.params = action.payload;
		},
		startRelatedProductsLoading: (state) => {
			state.relatedProductLoading = true;
		},
		stopRelatedProductsLoading: (state) => {
			state.relatedProductLoading = false;
		},
		loadRelatedProducts: (state, action: PayloadAction<any[]>) => {
			state.relatedProductLoading = false;
			if (state.record)
				state.record.related_products = [
					...(state.record.related_products ?? []),
					...action.payload,
				];
		},
	},
});

export async function setParams(params: object): Promise<void> {
	dispatch(productSlice.actions.setParams(params));
}

export async function getSpecificationTypes(): Promise<void> {
	const response = await axios.get(ENDPOINTS.shop.product.specification.getType);
	dispatch(productSlice.actions.getSpecificationTypes(response.data.response));
}

export async function getAll(params?: object): Promise<void> {
	try {
		dispatch(productSlice.actions.startLoading());
		const response = await axios.get(ENDPOINTS.shop.product.data.getAll, {
			params,
		});
		dispatch(productSlice.actions.getList(response.data.response));
	} catch (error) {
		dispatch(productSlice.actions.stopLoading());
		throw error;
	}
}

export async function getInfo(id: string | number): Promise<void> {
	try {
		dispatch(productSlice.actions.startLoading());
		const response = await axios.get(ENDPOINTS.shop.product.data.getInfo, {
			params: { product_id: id },
		});
		const responseVariants = await axios.get(
			ENDPOINTS.shop.product.variant.getAll,
			{ params: { product_id: id } }
		);
		const responseRelatedProducts = await axios.get(
			ENDPOINTS.shop.product.related.getAll,
			{ params: { product_id: id } }
		);
		dispatch(
			productSlice.actions.getRecord({
				...response.data.response,
				variants: responseVariants.data.response,
				related_products: responseRelatedProducts.data.response,
			})
		);
	} catch (error) {
		dispatch(productSlice.actions.stopLoading());
		throw error;
	}
}

export async function add(data: any): Promise<number> {
	try {
		dispatch(productSlice.actions.startLoading());
		const response = await axios.post(ENDPOINTS.shop.product.data.add, data);
		dispatch(productSlice.actions.stopLoading());
		return response.data.response.product_id;
	} catch (error) {
		dispatch(productSlice.actions.stopLoading());
		throw error;
	}
}

export async function update(data: any): Promise<void> {
	try {
		dispatch(productSlice.actions.startLoading());
		await axios.post(ENDPOINTS.shop.product.data.update, data);
		dispatch(productSlice.actions.stopLoading());
	} catch (error) {
		dispatch(productSlice.actions.stopLoading());
		throw error;
	}
}

export async function activate(id: string | number): Promise<void> {
	try {
		dispatch(productSlice.actions.startLoading());
		await axios.post(ENDPOINTS.shop.product.data.activate, { product_id: id });
		dispatch(productSlice.actions.activateRecord(id));
	} catch (error) {
		dispatch(productSlice.actions.stopLoading());
		throw error;
	}
}

export async function deactivate(id: string | number): Promise<void> {
	try {
		dispatch(productSlice.actions.startLoading());
		await axios.post(ENDPOINTS.shop.product.data.deactivate, {
			product_id: id,
		});
		dispatch(productSlice.actions.deactivateRecord(id));
	} catch (error) {
		dispatch(productSlice.actions.stopLoading());
		throw error;
	}
}

export async function deleteRecord(id: string | number): Promise<void> {
	try {
		dispatch(productSlice.actions.startLoading());
		await axios.post(ENDPOINTS.shop.product.data.delete, { product_id: id });
		dispatch(productSlice.actions.deleteRecord(id));
	} catch (error) {
		dispatch(productSlice.actions.stopLoading());
		throw error;
	}
}

export async function loadRelatedProducts(params: object): Promise<void> {
	try {
		dispatch(productSlice.actions.startRelatedProductsLoading());
		const response = await axios.get(ENDPOINTS.shop.product.related.getAll, {
			params,
		});
		dispatch(productSlice.actions.loadRelatedProducts(response.data.response));
	} catch (error) {
		dispatch(productSlice.actions.stopRelatedProductsLoading());
		throw error;
	}
}

export async function addRelatedProducts(data: object): Promise<void> {
	await axios.post(ENDPOINTS.shop.product.related.add, data);
}

export async function deleteRelatedProducts(data: object): Promise<void> {
	await axios.post(ENDPOINTS.shop.product.related.remove, data);
}

export async function sortRelatedProducts(data: object): Promise<void> {
	await axios.post(ENDPOINTS.shop.product.related.sorting, data);
}

export async function mediaAdd(data: any): Promise<void> {
	try {
		await axios.post(ENDPOINTS.shop.product.media.add, data);
	} catch (error) {
		///
	}
}

export async function mediaUpdate(data: any): Promise<void> {
	try {
		await axios.post(ENDPOINTS.shop.product.media.update, data);
	} catch (error) {
		///
	}
}

export async function updateOptions(data: any): Promise<any> {
	await axios.post(ENDPOINTS.shop.product.option.update, data);
	const responseProduct = await axios.get(ENDPOINTS.shop.product.data.getInfo, {
		params: { product_id: data.product_id },
	});
	const responseVariants = await axios.get(
		ENDPOINTS.shop.product.variant.getAll,
		{ params: { product_id: data.product_id } }
	);
	const responseRelatedProducts = await axios.get(
		ENDPOINTS.shop.product.related.getAll,
		{ params: { product_id: data.product_id } }
	);
	const record = {
		...responseProduct.data.response,
		variants: responseVariants.data.response,
		related_products: responseRelatedProducts.data.response,
	};
	dispatch(productSlice.actions.getRecord(record));
	return record;
}

export async function updateVariant(data: any): Promise<void> {
	await axios.post(ENDPOINTS.shop.product.variant.update, data);
}

export async function activateVariant(data: any): Promise<void> {
	await axios.post(ENDPOINTS.shop.product.variant.activate, data);
}

export async function deactivateVariant(data: any): Promise<void> {
	await axios.post(ENDPOINTS.shop.product.variant.deactivate, data);
}

export async function addSeo(data: any): Promise<void> {
	await axios.post(ENDPOINTS.shop.product.seo.add, data);
}
