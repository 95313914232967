import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { axiosInstance as axios } from '../../utils/axios';
import { dispatch } from '../store';
import { ENDPOINTS } from '../../config/endpoints';

interface CollectionModel {
	collection_id: number;
	collection_code?: string;
	name_ar?: string;
	name_en?: string;
	is_brand?: number;
	is_active?: number;
	is_deleted?: number;
	total_products?: number;
	related_products?: any[];
	created_at?: string;
	updated_at?: string;
	banner?: {
		collection_banner_id: number;
		banner_id: number;
		name_ar: string;
		name_en: string;
	};
}

interface CollectionSliceState {
	loading: boolean;
	relatedProductLoading: boolean;
	record?: CollectionModel;
	list: CollectionModel[];
	count: number;
	params: object;
}

export const brandSlice = createSlice({
	name: 'brand',
	initialState: {
		loading: false,
		relatedProductLoading: false,
		list: [],
		count: 0,
		params: {},
	} as CollectionSliceState,
	reducers: {
		startLoading: (state) => {
			state.loading = true;
		},
		stopLoading: (state) => {
			state.loading = false;
		},
		getList: (
			state,
			action: PayloadAction<{ count: number; list: CollectionModel[] }>
		) => {
			state.count = action.payload.count;
			state.list = action.payload.list;
			state.loading = false;
		},
		getRecord: (state, action: PayloadAction<CollectionModel>) => {
			state.record = action.payload;
			state.loading = false;
		},
		deleteRecord: (state, action: PayloadAction<string | number>) => {
			state.record = undefined;
			state.list = state.list.filter(
				(item) => item.collection_id === action.payload
			);
			state.loading = false;
		},
		activateRecord: (state, action: PayloadAction<string | number>) => {
			if (state.record?.collection_id === action.payload)
				state.record.is_active = 1;
			state.list = state.list.map((item) =>
				item.collection_id === action.payload ? { ...item, is_active: 1 } : item
			);
			state.loading = false;
		},
		deactivateRecord: (state, action: PayloadAction<string | number>) => {
			if (state.record?.collection_id === action.payload)
				state.record.is_active = 0;
			state.list = state.list.map((item) =>
				item.collection_id === action.payload ? { ...item, is_active: 0 } : item
			);
			state.loading = false;
		},
		setParams: (state, action: PayloadAction<object>) => {
			state.params = action.payload;
		},
		startRelatedProductsLoading: (state) => {
			state.relatedProductLoading = true;
		},
		stopRelatedProductsLoading: (state) => {
			state.relatedProductLoading = false;
		},
		loadRelatedProducts: (state, action: PayloadAction<any[]>) => {
			state.relatedProductLoading = false;
			if (state.record)
				state.record.related_products = [
					...(state.record.related_products ?? []),
					...action.payload,
				];
		},
	},
});

export async function setParams(params: object): Promise<void> {
	dispatch(brandSlice.actions.setParams(params));
}

export async function getAll(params?: object): Promise<void> {
	try {
		dispatch(brandSlice.actions.startLoading());
		const response = await axios.get(ENDPOINTS.shop.collection.data.getAll, {
			params: { ...params, is_brand: 1 },
		});
		dispatch(brandSlice.actions.getList(response.data.response));
	} catch (error) {
		dispatch(brandSlice.actions.stopLoading());
		throw error;
	}
}

export async function getInfo(id: string | number): Promise<void> {
	try {
		dispatch(brandSlice.actions.startLoading());
		const response = await axios.get(ENDPOINTS.shop.collection.data.getInfo, {
			params: { collection_id: id },
		});
		const responseRelatedProducts = await axios.get(
			ENDPOINTS.shop.collection.product.getAll,
			{ params: { collection_id: id } }
		);
		const data = {
			...response.data.response,
			banner: undefined,
			related_products: responseRelatedProducts.data.response,
		};
		try {
			const bannerResponse = await axios.get(
				ENDPOINTS.shop.collection.banner.getInfo,
				{
					params: { collection_id: id },
				}
			);
			data.banner = {
				collection_banner_id: bannerResponse.data.response.collection_banner_id,
				banner_id: bannerResponse.data.response.banner_id,
				name_ar: bannerResponse.data.response.banner_info.name_ar,
				name_en: bannerResponse.data.response.banner_info.name_en,
			};
		} catch (error) {
			//
		}
		dispatch(brandSlice.actions.getRecord(data));
	} catch (error) {
		dispatch(brandSlice.actions.stopLoading());
		throw error;
	}
}

export async function add(data: any): Promise<number> {
	try {
		dispatch(brandSlice.actions.startLoading());
		const response = await axios.post(ENDPOINTS.shop.collection.data.add, data);
		dispatch(brandSlice.actions.stopLoading());
		return response.data.response.collection_id;
	} catch (error) {
		dispatch(brandSlice.actions.stopLoading());
		throw error;
	}
}

export async function update(data: any): Promise<void> {
	try {
		dispatch(brandSlice.actions.startLoading());
		await axios.post(ENDPOINTS.shop.collection.data.update, data);
		dispatch(brandSlice.actions.stopLoading());
	} catch (error) {
		dispatch(brandSlice.actions.stopLoading());
		throw error;
	}
}

export async function activate(id: string | number): Promise<void> {
	try {
		dispatch(brandSlice.actions.startLoading());
		await axios.post(ENDPOINTS.shop.collection.data.activate, {
			collection_id: id,
		});
		dispatch(brandSlice.actions.activateRecord(id));
	} catch (error) {
		dispatch(brandSlice.actions.stopLoading());
		throw error;
	}
}

export async function deactivate(id: string | number): Promise<void> {
	try {
		dispatch(brandSlice.actions.startLoading());
		await axios.post(ENDPOINTS.shop.collection.data.deactivate, {
			collection_id: id,
		});
		dispatch(brandSlice.actions.deactivateRecord(id));
	} catch (error) {
		dispatch(brandSlice.actions.stopLoading());
		throw error;
	}
}

export async function deleteRecord(id: string | number): Promise<void> {
	try {
		dispatch(brandSlice.actions.startLoading());
		await axios.post(ENDPOINTS.shop.collection.data.delete, {
			collection_id: id,
		});
		dispatch(brandSlice.actions.deleteRecord(id));
	} catch (error) {
		dispatch(brandSlice.actions.stopLoading());
		throw error;
	}
}

export async function loadRelatedProducts(params: object): Promise<void> {
	try {
		dispatch(brandSlice.actions.startRelatedProductsLoading());
		const response = await axios.get(ENDPOINTS.shop.collection.product.getAll, {
			params,
		});
		dispatch(brandSlice.actions.loadRelatedProducts(response.data.response));
	} catch (error) {
		dispatch(brandSlice.actions.stopRelatedProductsLoading());
		throw error;
	}
}

export async function addRelatedProducts(data: object): Promise<void> {
	await axios.post(ENDPOINTS.shop.collection.product.add, data);
}

export async function deleteRelatedProducts(data: object): Promise<void> {
	await axios.post(ENDPOINTS.shop.collection.product.delete, data);
}

export async function sortRelatedProducts(data: object): Promise<void> {
	await axios.post(ENDPOINTS.shop.collection.product.update, data);
}
