import React, { useState, useRef } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { useLocales } from '../../hooks/useLocales';
import { useTheme } from '../../hooks/useTheme';
import { useAuth } from '../../hooks/useAuth';
import Formatter from '../../utils/formatter';
import default_avatar from '../../assets/images/default_avatar.png';
// Redux
import { logout } from '../../redux/slices/auth';
//
import packageFile from '../../../package.json';

function AccountMenu(): React.ReactNode {
	const { user } = useAuth();
	const { currentLang, otherLangs, changeLang, translate } = useLocales();
	const { theme } = useTheme();
	const dropdownRef = useRef<Dropdown>(null);
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
	return (
		<Dropdown
			ref={dropdownRef}
			isOpen={isMenuOpen}
			toggle={() => {
				setIsMenuOpen(!isMenuOpen);
			}}
		>
			<DropdownToggle className={'naked'}>
				<div
					className={'p-0 m-0 d-flex justify-content-end align-items-center'}
				>
					<div className={'p-0 m-0 px-2 d-none d-xl-flex'}>
						<div
							className={` p-0 m-0 text-justify d-none d-lg-block ${
								theme === 'light'
									? `t-${theme}-text-alternative-highlight`
									: `t-${theme}-text-primary-highlight`
							} font-md `}
						>
							{` ${translate('layout.accountMenu.hi')} 👋 `}
						</div>

						<div
							className={`p-0 px-1 m-0 d-none d-lg-block text-justify ${
								theme === 'light'
									? `t-${theme}-text-alternative-highlight`
									: `t-${theme}-text-primary-highlight`
							} font-md`}
						>
							{user?.display_name
								? Formatter.longStringToShortWithDots(user?.display_name, 12, 3)
								: `${translate('layout.accountMenu.admin')}`}
						</div>
					</div>
					<div
						className={`m-0 ${
							currentLang.dir === 'ltr' ? 'ps-3' : 'pe-3'
						} d-none d-md-block `}
					>
						<div
							style={{
								backgroundImage: user?.avatar_url
									? `url(${user?.avatar_url})`
									: `url(${default_avatar})`,
							}}
							className={'pointer opacity-hover m-0 masterLayout-user-avatar'}
						/>
					</div>
				</div>
			</DropdownToggle>
			<DropdownMenu
				end={currentLang.dir === 'ltr'}
				className={`option-dropdown t-${theme}-bg-secondary t-${theme}-text-primary-highlight ${currentLang.dir}-pull-to-leading rounded-sm p-2`}
			>
				<div
					className={`p-3 t-${theme}-hover-secondary-highlight ${currentLang.dir}-secondaryFont font-md borderless opacity-hover`}
				>
					{`V${packageFile.version}`}
				</div>
				{otherLangs.map((item, index: number) => (
					<div
						key={index}
						className={`p-3 pointer t-${theme}-hover-secondary-highlight ${currentLang.dir}-secondaryFont font-md borderless opacity-hover`}
						onClick={() => {
							changeLang(item.value);
							setIsMenuOpen(false);
						}}
					>
						{item.title}
					</div>
				))}
				<div
					className={`p-3 pointer t-${theme}-hover-secondary-highlight ${currentLang.dir}-secondaryFont font-md borderless opacity-hover`}
					onClick={() => {
						logout();
						setIsMenuOpen(false);
					}}
				>
					{translate('auth.logout.title')}
				</div>
			</DropdownMenu>
		</Dropdown>
	);
}

export default AccountMenu;
