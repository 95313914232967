import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
// Hooks
import { useLocales } from '../../hooks/useLocales';
import { useTheme } from '../../hooks/useTheme';
import { CONFIG } from '../../config';

import default_avatar from '../../assets/images/default_avatar.png';
import icon_close from '../../assets/images/icon_close.png';
import icon_chevronForward from '../../assets/images/icon_chevronForward.png';
import icon_chevronBack from '../../assets/images/icon_chevronBack.png';

import '../../assets/scss/headsUpView.scss';

interface MobileMenuProps {
	mobileMenuOpen: boolean;
	toggleMobileMenu: Function;
}

function MobileMenu({
	mobileMenuOpen,
	toggleMobileMenu,
}: MobileMenuProps): React.ReactNode {
	const { currentLang, translate } = useLocales();
	const { theme } = useTheme();
	const navigate = useNavigate();
	const navMenuState = mobileMenuOpen ? 'open' : 'closed';

	const onMenuClick = (item: any): void => {
		navigate(item.path);
		toggleMobileMenu();
	};
	return (
		<div className={'d-md-none overflow-hidden m-0 p-0'}>
			<div
				className={`d-md-none ${
					currentLang.dir
				}-secondaryFont t-${theme}-text-primary-highlight ${
					currentLang.dir
				}-headsUpView-navMenu-container-${navMenuState} h-100 ${
					theme === 'light' ? 't-light-bg-exception' : `t-${theme}-bg-secondary`
				}`}
			>
				<Container
					className={`themed-container m-0 p-0 pt-2 pb-3 px-3 w-100 font-sm ${
						theme === 'light'
							? 't-light-bg-dark'
							: `t-${theme}-bg-secondary-highlight`
					} t-${theme}-text-primary-highlight text-center`}
					fluid={true}
				>
					<Row className='m-0 p-0 justify-content-end'>
						<Col
							xs={1}
							md={0}
							className={`p-0 py-2 px-2 pointer opacity-hover ${currentLang.dir}-pull-to-trailing`}
							onClick={() => toggleMobileMenu()}
						>
							<Image
								src={icon_close}
								alt={'icon_close'}
								width={'10px'}
							/>
						</Col>
					</Row>
				</Container>
				<Container
					className={`themed-container m-0 p-0 pt-2 pb-3 px-4 w-100 font-sm ${
						theme === 'light'
							? 't-light-bg-dark'
							: `t-${theme}-bg-secondary-highlight`
					} t-${theme}-text-primary-highlight text-center`}
					fluid={true}
				>
					<Row className={'m-0 p-0 py-1'}>
						<Col className={'p-0 pointer'}>
							<div
								style={{ backgroundImage: `url(${default_avatar})` }}
								className={
									'pointer mx-auto opacity-hover headsUpView-user-avatar'
								}
							/>
						</Col>
					</Row>
					<Row className={'m-0 p-0 py-1'}>
						<div className='d-flex align-items-baseline justify-content-center'>
							<div
								className={`p-0 m-0 text-justify t-${theme}-text-primary-highlight font-md`}
							>
								{`${translate('layout.accountMenu.admin')}`}
							</div>
						</div>
						<p
							className={`p-0 py-2 ltr ${
								theme === 'light'
									? `t-${theme}-text-primary-highlight`
									: `t-${theme}-text-alternative`
							} text-center font-xs pointer opacity-hover`}
						>
							<span>@</span>
							{`${translate('layout.accountMenu.admin')}`}
						</p>
					</Row>
				</Container>
				<Container
					className={`themed-container m-0 p-0 pt-2 w-100 h-100 font-sm ${currentLang.dir}-pull-to-leading`}
					fluid={true}
				>
					<div className={'headsUpView-categories-container scrollable-y'}>
						<Row className='m-0 p-0'>
							{Object.values(CONFIG.Apps).map((item, index) => (
								<Row
									key={index}
									className={
										'm-0 p-0 p-3 justify-content-between font-md pointer opacity-hover align-items-center'
									}
									onClick={() => {
										onMenuClick(item);
									}}
								>
									<Col
										xs={1}
										md={0}
										className='p-0 px-2'
									>
										<Image
											src={item.icon.active}
											alt={'icon_home'}
											width={'17px'}
										/>
									</Col>
									<Col
										xs={9}
										md={0}
										className={`p-0 px-2 align-self-end ${currentLang.dir}-pull-to-leading`}
									>
										{translate(item.title)}
									</Col>
									<Col
										xs={1}
										md={0}
										className='p-0 px-2'
									>
										{currentLang.dir === 'rtl' ? (
											<Image
												src={icon_chevronBack}
												alt={'icon_chevronBack'}
												width={'5px'}
											/>
										) : (
											<Image
												src={icon_chevronForward}
												alt={'icon_chevronForward'}
												width={'5px'}
											/>
										)}
									</Col>
								</Row>
							))}
						</Row>
					</div>
				</Container>
			</div>

			<div
				onClick={() => toggleMobileMenu()}
				className={`d-md-none headsUpView-navMenu-backdrop-${navMenuState} h-100 w-100`}
			/>
		</div>
	);
}

export default MobileMenu;
