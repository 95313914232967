import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

interface GuardProps {
	children?: React.ReactNode;
}

function GuestGuard({ children }: GuardProps): React.ReactNode {
	const { user } = useAuth();
	if (user) return <Navigate to={'/'} />;
	return <>{children}</>;
}

export default GuestGuard;
