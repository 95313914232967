import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { axiosInstance as axios } from '../../utils/axios';
import { dispatch } from '../store';
import { ENDPOINTS } from '../../config/endpoints';

export interface StreamModel {
	channel_id: number;
	name_ar?: string;
	name_en?: string;
	sort_id?: number;
	url_gen_ar?: string;
	url_ios_ar?: string;
	url_and_ar?: string;
	url_gen_en?: string;
	url_ios_en?: string;
	url_and_en?: string;
	img_web_ar?: string;
	img_web_en?: string;
	img_app_ar?: string;
	img_app_en?: string;
	is_active?: number;
	is_deleted?: number;
	created_at?: string;
	updated_at?: string;
}

interface StreamSliceState {
	loading: boolean;
	record?: StreamModel;
	list: StreamModel[];
	count: number;
	params: object;
}

export const streamSlice = createSlice({
	name: 'stream',
	initialState: {
		loading: false,
		list: [],
		count: 0,
		params: {},
	} as StreamSliceState,
	reducers: {
		startLoading: (state) => {
			state.loading = true;
		},
		stopLoading: (state) => {
			state.loading = false;
		},
		getList: (
			state,
			action: PayloadAction<{ count: number; data: StreamModel[] }>
		) => {
			state.count = action.payload.count;
			state.list = action.payload.data;
			state.loading = false;
		},
		getRecord: (state, action: PayloadAction<StreamModel>) => {
			state.record = action.payload;
			state.loading = false;
		},
		deleteRecord: (state, action: PayloadAction<string | number>) => {
			state.record = undefined;
			state.list = state.list.filter(
				(item) => item.channel_id === action.payload
			);
			state.loading = false;
		},
		activateRecord: (state, action: PayloadAction<string | number>) => {
			if (state.record?.channel_id === action.payload)
				state.record.is_active = 1;
			state.list = state.list.map((item) =>
				item.channel_id === action.payload ? { ...item, is_active: 1 } : item
			);
			state.loading = false;
		},
		deactivateRecord: (state, action: PayloadAction<string | number>) => {
			if (state.record?.channel_id === action.payload)
				state.record.is_active = 0;
			state.list = state.list.map((item) =>
				item.channel_id === action.payload ? { ...item, is_active: 0 } : item
			);
			state.loading = false;
		},
		clearRecord: (state) => {
			state.record = undefined;
			state.loading = false;
		},
		setParams: (state, action: PayloadAction<object>) => {
			state.params = action.payload;
		},
	},
});

export async function setParams(params: object): Promise<void> {
	dispatch(streamSlice.actions.setParams(params));
}

export async function getAll(params?: object): Promise<void> {
	try {
		dispatch(streamSlice.actions.startLoading());
		const response = await axios.get(ENDPOINTS.content.stream.data.getAll, {
			params,
		});
		dispatch(streamSlice.actions.getList(response.data.response));
	} catch (error) {
		dispatch(streamSlice.actions.stopLoading());
		throw error;
	}
}

export async function getInfo(id: string | number): Promise<void> {
	try {
		dispatch(streamSlice.actions.startLoading());
		const response = await axios.get(ENDPOINTS.content.stream.data.getInfo, {
			params: { channel_id: id },
		});
		dispatch(streamSlice.actions.getRecord(response.data.response));
	} catch (error) {
		dispatch(streamSlice.actions.clearRecord());
		throw error;
	}
}

export async function add(data: any): Promise<number> {
	try {
		dispatch(streamSlice.actions.startLoading());
		const response = await axios.post(ENDPOINTS.content.stream.data.add, data);
		dispatch(streamSlice.actions.stopLoading());
		return response.data.response.channel_id;
	} catch (error) {
		dispatch(streamSlice.actions.stopLoading());
		throw error;
	}
}

export async function update(data: any): Promise<void> {
	try {
		dispatch(streamSlice.actions.startLoading());
		await axios.post(ENDPOINTS.content.stream.data.update, data);
		dispatch(streamSlice.actions.stopLoading());
	} catch (error) {
		dispatch(streamSlice.actions.stopLoading());
		throw error;
	}
}

export async function activate(id: string | number): Promise<void> {
	try {
		dispatch(streamSlice.actions.startLoading());
		await axios.post(ENDPOINTS.content.stream.data.activate, {
			channel_id: id,
		});
		dispatch(streamSlice.actions.activateRecord(id));
	} catch (error) {
		dispatch(streamSlice.actions.stopLoading());
		throw error;
	}
}

export async function deactivate(id: string | number): Promise<void> {
	try {
		dispatch(streamSlice.actions.startLoading());
		await axios.post(ENDPOINTS.content.stream.data.deactivate, {
			channel_id: id,
		});
		dispatch(streamSlice.actions.deactivateRecord(id));
	} catch (error) {
		dispatch(streamSlice.actions.stopLoading());
		throw error;
	}
}

export async function deleteRecord(id: string | number): Promise<void> {
	try {
		dispatch(streamSlice.actions.startLoading());
		await axios.post(ENDPOINTS.content.stream.data.delete, {
			channel_id: id,
		});
		dispatch(streamSlice.actions.deleteRecord(id));
	} catch (error) {
		dispatch(streamSlice.actions.stopLoading());
		throw error;
	}
}
