import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { axiosInstance as axios } from '../../utils/axios';
import { dispatch } from '../store';
import { ENDPOINTS } from '../../config/endpoints';

export interface FlagModel {
	flag_id: number;
	is_resolved: number;
	reporter_username: string;
	flag_code: string;
	flag_type_ar: string;
	flag_type_en: string;
	flag_content?: string;
	reference_content_id: string;
	reference_content_type: 'tournament' | 'chat';
	updated_at: string;
	created_at: string;
}
export interface FlagDetailsModel {
	flag_id: number;
	is_resolved: number;
	reporter_username: string;
	reported_username: string;
	resolver_display_name: string;
	resolver_email: string;
	flag_code: string;
	flag_type_ar: string;
	flag_type_en: string;
	flag_content?: string;
	reference_content_id: string;
	reference_content_type: 'tournament' | 'chat';
	created_at: string;
	updated_at: string;
}

interface FlagSliceState {
	loading: boolean;
	record?: FlagDetailsModel;
	list: FlagModel[];
	count: number;
	params: object;
}

export const flagSlice = createSlice({
	name: 'flag',
	initialState: {
		loading: false,
		list: [],
		count: 0,
		params: {},
	} as FlagSliceState,
	reducers: {
		startLoading: (state) => {
			state.loading = true;
		},
		stopLoading: (state) => {
			state.loading = false;
		},
		getList: (
			state,
			action: PayloadAction<{ count: number; data: FlagModel[] }>
		) => {
			state.count = action.payload.count;
			state.list = action.payload.data;
			state.loading = false;
		},
		getRecord: (state, action: PayloadAction<FlagDetailsModel>) => {
			state.record = action.payload;
			state.loading = false;
		},
		clearRecord: (state) => {
			state.record = undefined;
			state.loading = false;
		},
		setParams: (state, action: PayloadAction<object>) => {
			state.params = action.payload;
		},
	},
});

export async function setParams(params: object): Promise<void> {
	dispatch(flagSlice.actions.setParams(params));
}

export async function getAll(params?: object): Promise<void> {
	try {
		dispatch(flagSlice.actions.startLoading());
		const response = await axios.get(ENDPOINTS.content.flag.data.getAll, {
			params,
		});
		dispatch(flagSlice.actions.getList(response.data.response));
	} catch (error) {
		dispatch(flagSlice.actions.stopLoading());
		throw error;
	}
}

export async function getInfo(flag_id: number | string): Promise<void> {
	try {
		dispatch(flagSlice.actions.startLoading());
		const response = await axios.get(ENDPOINTS.content.flag.data.getInfo, {
			params: { flag_id },
		});
		dispatch(flagSlice.actions.getRecord(response.data.response));
	} catch (error) {
		dispatch(flagSlice.actions.clearRecord());
		throw error;
	}
}

export async function resolveFlag(flag_id: number | string): Promise<void> {
	await axios.post(ENDPOINTS.content.flag.data.resolve, { flag_id });
}
