import axios from 'axios';

const apiKey =
	process.env.REACT_APP_ENV_STAGE === 'PROD'
		? 'qpOKJNSsUD5y7KVJIPRiH1S92geRd8JnICfL64Ub'
		: process.env.REACT_APP_ENV_STAGE === 'PREPROD'
		? 'qpOKJNSsUD5y7KVJIPRiH1S92geRd8JnICfL64Ub'
		: process.env.REACT_APP_ENV_STAGE === 'STAGING'
		? 'NkcAwSTMxI1Rd0d6WhdY99v59TAT5Hsk2fs4Yr7Z'
		: 'wRFYcmCfEkaDtWGtrMGUUafLoHxySdUF5hC9BaRH';

const axiosInstance = axios.create({
	baseURL: '/v3',
	headers: {
		'Cache-Control': 'no-cache',
		Pragma: 'no-cache',
		'Content-Type': 'application/json',
		Accept: 'application/json',
		'x-api-key': apiKey,
		'x-api-endpoint': 'web',
		'x-api-lang': 'en',
		'x-api-token': '',
	},
});

const setSession = (token?: string): void => {
	if (token) {
		axiosInstance.defaults.headers['x-api-token'] = token;
	} else {
		axiosInstance.defaults.headers['x-api-token'] = '';
	}
};

export { axiosInstance, setSession };
